.loading-box {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  align-items: center;
  background-color: var(--main-color);
}
.full {
  position: fixed;
  z-index: 50;
  background: rgba(255, 255, 255, 0.5);
}

/* Modify using the right process */
.loading-box span {
  width: 100px !important;
  height: 100px !important;
}
