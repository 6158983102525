.formAddressContainer {
  margin: 20px 0;
}

.inputMapAddress {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 25px;
  border-radius: 6px;
  border: 1px solid #c8c8c8;
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
}

.requiredMessage {
  font-size: 16px;
  margin-bottom: 10px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
}

.inputContainer > input {
  padding: 10px 25px;
  font-size: 14px;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
}

.errorMessage {
  color: red;
  font-size: 12px;
}

.selecContainer {
  margin-bottom: 0;
}

.hidden {
  display: none;
}