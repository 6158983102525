.modal-box-generate-sales-order {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}
.modal-box-generate-sales-order-container {
  padding: 30px;
}

.close-modal {
  font-size: 20px;
}

.close-modal:hover {
  cursor: pointer;
}

.confirmation-header-modal {
  text-align: right;
  padding: 0 9px;
  margin-bottom: 10px;
}

.modal-box-generate-sales-order .modal-buttons {
  margin-top: 25px;
  display: flex;
  padding: 0;
  gap: 15px;
  justify-content: end;
}

.modal-box-generate-sales-order .modal-buttons .btn {
  font-family: inherit;
  outline: none;
  font-size: 1.7rem;
  padding: 12px 20px;
  border-radius: 5px;
}

.modal-box-generate-sales-order .modal-buttons .btn.modal-si {
  background-color: var(--secondary-color);
  color: var(--main-color);
  border: none;
  padding: 12px 46px;
}

.modal-box-generate-sales-order .modal-buttons .btn.modal-no {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}

.confirmation-message {
  text-align: center;
  padding: 0 24px;
}

.confirmation-message img {
  width: 15%;
  margin-bottom: 9px;
}

.confirmation-message .confirmation-text-delete {
  font-size: 1.7em;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.17px;
  color: #010101;
  opacity: 1;
  text-align: center;
  margin: 3px 0;
}

.form-field-title {
  font-weight: bold;
  font-size: 1.4rem;
}

.form-field-option {
  font-size: 1.4rem;
  place-content: center;
}
.form-field-option input[type='radio']:disabled {
  cursor: not-allowed;
}
.form-field-option input[type='radio'] {
  cursor: pointer
}

.form-field-option span {
  margin-left: 5px;
}

.form-field-text-input {
  margin-top: 10px;
  padding: 12px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 400;
}

.add-file {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.add-file input[type='file'] {
  padding: 12px;
  border: 1px solid #e3e3e3;
  outline: none;
  width: 80%;
  border-radius: 0px 5px 5px 0px;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 400;
}

.add-file input[type='file']::file-selector-button {
  display: none;
}

.add-file label {
  text-align: center;
  width: 20%;
  padding: 12px;
  font-size: 1.4rem;
  border-radius: 5px 0px 0px 5px;
  font-weight: 400;
  color: var(--main-color);
  background-color: var(--secondary-color);
}

.add-file-label {
  padding: 12px;
  border: 1px solid #e3e3e3;
  outline: none;
  width: 80%;
  border-radius: 0px 5px 5px 0px;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 400;
}

.add-file-label-placeholder {
  padding: 12px;
  border: 1px solid #e3e3e3;
  outline: none;
  width: 80%;
  border-radius: 0px 5px 5px 0px;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--placeholder-color);
}

.add-file svg {
  vertical-align: unset;
}

.field-error-msg {
  font-size: 1.2rem;
  color: red;
  font-weight: normal;
}
