.modal-box-confirm-start-sales-order {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}
.delete-confirm-container {
  padding: 45px 17.5px 42px 17px;
}

.close-modal {
  font-size: 20px;
}

.close-modal:hover {
  cursor: pointer;
}

.confirmation-header-modal {
  text-align: right;
  padding: 0 9px;
  margin-bottom: 10px;
}

.modal-box-confirm-start-sales-order .modal-buttons {
  margin-top: 25px;
  display: flex;
  gap: 15px;
  justify-content: center;
}

.modal-box-confirm-start-sales-order .modal-buttons .btn {
  font-family: inherit;
  outline: none;
  font-size: 1.7rem;
  padding: 12px 20px;
  border-radius: 5px;
}

.modal-box-confirm-start-sales-order .modal-buttons .btn.modal-si {
  background-color: var(--secondary-color);
  color: var(--main-color);
  border: none;
  padding: 12px 26px;
}

.modal-box-confirm-start-sales-order .modal-buttons .btn.modal-no {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}

.confirmation-message {
  text-align: center;
  padding: 0 24px;
}

.confirmation-message img {
  width: 15%;
  margin-bottom: 9px;
}

.confirmation-message .confirmation-text-delete {
  font-size: 1.9em;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.17px;
  color: #010101;
  opacity: 1;
  text-align: center;
  margin: 3px 0;
}
