
  .container-SD {
    display: flex;
    flex-direction: column;
    padding: calc(var(--padding-top-bar) + 8px) 12px 12px
      calc(var(--padding-left-bar) + 12px);
    height: 100%;
    background-color: var(--main-color);
    width: calc(100vw - 491px);
    overflow: auto;
    overflow-x: auto;
  }

  .single-product-cart {
    width: 100%;
    margin-top: 12px;
  }

  .single-product-cart thead tr th{
    padding: 12px 21px;
    font-size: 14px;
    color: #5b5b5b;
    background-color: #f8f9fa;
    border: 1px solid var(--border-light-color);
    font-weight: var(--regular);
  }
  
  .single-product-cart-a tbody tr td {
    padding: 12px 21px;
    font-size: 14px;
    color: #5b5b5b;
    background-color: #f8f9fa;;
    border: 1px solid var(--border-light-color);
    font-weight: var(--regular);
}

  .inventory-table {
    width: 100%;
    min-width: 1134px;
  }
  
  .inventory-table.products {
     min-width: unset;
  }

.p-color {
    margin-left: 2rem;
    margin-bottom: 1rem;
    font-size:small;
}
.h2-space {
    margin-bottom: 1rem;
    margin-top: 3rem;
    margin-left: 2rem;
    color: var(--secondary-color);
    font-size:large;
}

.table-sapce {
  width: 100%;
  padding: 0 20px;
}