.vad-content__main {
  padding-top: var(--padding-top-bar);
  padding-left: var(--padding-left-bar);
  padding-bottom: 20px;
}

.vad-content__main-header {
  padding: 15px 44px;
  background-color: var(--main-color);
  box-shadow: 0px 1px 4px #15223214;
}

.vad-content__main-title {
  font-size: 18px;
  letter-spacing: 0.36px;
  color: var(--secondary-color);
}

.vad-content__wrapper {
  padding: 31px 63px 10px 63px;
}
