.tareas-main {
  padding-top: var(--padding-top-bar);
  padding-left: var(--padding-left-bar);
}

.tareas-header {
  box-shadow: 0px 1px 4px #15223214;
  padding: 15px 35px;
  background-color: var(--main-color);
}

.tareas-header h2 {
  color: var(--secondary-color);
  font-size: 1.8rem;
  letter-spacing: 0.36px;
}
.tareas-content {
  padding: 21px 35px;
}

.tareas-search-bar {
  padding: 0;
}

.tareas-search-filter {
  background-color: var(--main-color);
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  padding: 12px 39px 13px 39px;
  height: 45px;
  position: relative;
  margin-left: 10px;
}

.task-search-icon{
    position: absolute;
    top: 27%;
    right: 1.8%;
    cursor: pointer;
  }

.tareas-filter-options{
    position: absolute;
    top: 54px;
    left: 0;
    z-index: 99999;
    background-color: var(--main-color);
    width: 100%;
    border-radius: 0 0 5px 5px;
    list-style: none;
    max-height: 400px;
    padding: 0;
    transition: all 0.1s;
}

.tareas-filter-options {
  position: absolute;
  top: 54px;
  left: 0;
  z-index: 99999;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  max-height: 400px;
  padding: 0;
  transition: all 0.1s;
}

.filter-option {
  font-size: 1.8rem;
  padding: 20px 39px;
}

.tareas-filter-options-hidden {
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 54px;
  left: 0;
  z-index: 99999;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  padding: 0;
  transition: all 0.1s;
}

.tareas-filter-options .filter-option:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
  font-weight: var(--semibold);
  cursor: pointer;
}

.filter-option label {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.filter-option .radio {
  display: none;
}

.tareas-filter-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tareas-filter-content .fa-chevron-down {
  font-size: 1.8rem;
  color: #7e7e7e;
}

.tareas-filter-content h2 {
  font-size: 0.8vw;
  line-height: 24px;
  color: #7e7e7e;
}

.tareas-search-bar input {
  outline: none;
  width: 100%;
  font-size: 1.8rem;
  letter-spacing: 0.36px;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  padding: 12px 39px 13px 39px;
  height: 45px;
}

.tareas-search-bar input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #7e7e7e;
  letter-spacing: 0.36px;
}

.tareas-listado {
  margin-top: 22px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 0 30px;
}

.mis-tareas-pagination {
  padding: 0 30px;
}

.error-message-mis-tareas {
  padding: 0 35px;
  text-align: center;
  font-size: 0.92vw;
  color: var(--secondary-color);
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .tareas-filter-content h2 {
    font-size: 0.9vw;
  }
}
