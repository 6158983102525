.horizontal-navbar {
  width: 100% !important;
  height: 65px;
  border: 1px solid #e7e9f1;
  background-color: var(--main-color);
  position: fixed;
}
.modal-up {
  z-index: 10001 !important;
  overflow: scroll;
  width: 90%;
}
.modal-up::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.horizontal-icons-container {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0 36px;
  height: 100%;
  align-items: center;
}

.display-image {
  display: block;
  height: auto;
  width: 40%;
}

.user {
  display: flex;
  align-items: center;
  font-weight: 400;
}

.profile-pic {
  background-color: var(--secondary-color);
  margin-right: 10px;
  padding: 5px;
  color: var(--main-color);
  border-radius: 5px;
  font-size: 1.2em;
}

.username {
  font-size: 1.6em;
  color: #131523;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
}

.h-nav-icon.tenant {
  display: flex;
  align-items: center;
}

.fa-chevron-down {
  margin-left: 13px;
  font-size: 1.7em;
}

.h-nav-icon.user {
  /* position: relative; */
  position: fixed;
  float: left;
  right: 36px;
  top: 18px;
}

@media only screen and (max-width: 768px) {
  .horizontal-icons-container {
    padding-left: 70px;
  }
}

@media only screen and (max-width: 350px) {
  .h-nav-icon.tenant {
    padding-left: 70px;
  }
}

.icon-branch-div {
  width: 221.81px;
}
