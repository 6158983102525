.quote-activity {
  padding: 17px 25px 16px 25px;
  border: 1px solid #f2f2f2;
  display: flex;
  background-color: var(--main-color);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4vw;
  position: relative;
}

.quote-activity-generals p {
  margin: 0;
  font-size: 0.92vw;
  letter-spacing: 0.32px;
}

.quote-activity-status {
  background-color: var(--open-quotes);
  border-radius: 5px;
}

.quote-activity-status p {
  font-size: 0.78vw;
  letter-spacing: 0.3px;
  margin: 0;
  padding: 5.8px 11.3px;
  text-align: center;
}

.quote-activity-tooltips {
  display: flex;
  justify-content: space-around;
}

.status-icon {
  position: absolute;
  top: 4px;
  left: 2px;
  width: 10px;
  height: 35px;
  border-radius: 5px;
  background-color: var(--open-quotes);
}

.strong-text {
  font-weight: 600;
}

.fa-square-pen {
  font-size: 1.27vw;
  color: #21c5df;
}

.fa-trash-can {
  font-size: 2em;
  color: #f64e60;
}
