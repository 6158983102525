#search-map-build {
  margin-bottom: 23px;
}

.add-address-perfil {
  margin-top: 19px;
  display: flex;
  align-items: center;
  font-size: 1.4rems;
  gap: 5px;
}

.add-address-perfil label {
  font-weight: var(--semibold);
}

.address-search-filter {
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
}

.address-filter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  height: 100%;
  padding: 10px 25px;
  width: 100%;
  border: none;
  background-color: white;
}

.address-filter-content h2 {
  font-size: 14px;
  font-weight: normal;
  color: #919191;
}

.address-search-filter {
  position: relative;
}

.address-filter-options {
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  max-height: 200px;
  padding: 0;
  overflow: scroll;
  transition: all 0.1s;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.address-filter-options-hidden {
  max-height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  padding: 0;
  transition: all 0.1s;
}

.address-filter-options .address-filter-option:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
  font-weight: var(--semibold);
  cursor: pointer;
}

.address-filter-option label {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.address-filter-option .radio {
  display: none;
}

.address-filter-option {
  font-size: 1.2rem;
  padding: 10px 30px;
}

.content-form-direccion-modal {
  background-color: white;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.content-form-direccion-modal-padding {
  padding: 23px 121px;
  width: 100%;
}

.content-form-direccion {
  margin-top: 30px;
  padding: 0 121px;
}

.content-form-direccion form input {
  outline: none;
}

.content-form-direccion form input::placeholder {
  color: #919191;
}

.content-form-direccion form input:focus:required:invalid {
  border: 2px solid red;
}

.content-form-direccion forminput:required:valid {
  border: 2px solid black;
}

.map-special-nopadding{
    margin-top: 16px;
}

.pac-container {
  z-index: 9000000000000;
}

.error-message-address {
  margin: 10px 0;
}

.add-direction-title-label-modal {
  font-size: 18px;
  margin-top: 80px;
  margin-bottom: 67px;
  margin-left: 79px;
  color: var(--secondary-color);
}
