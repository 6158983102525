.printer-topbar {
  background-color: #3488db;
  border: 1px solid var(--border-light-color);
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  height: 64px;
}

.printer-search-printer {
  width: 100% !important;
  background-color: #f8f9fa;
  font: normal normal 600 14px/19px Open Sans;
  text-align: center;
  margin-top: -30px;
}

.printer-search-printer .inv-search-icon {
  position: absolute;
  top: 13px;
  right: 18px;
  width: 2.5%;
  cursor: pointer;
}

.row-center-text {
  text-align: center !important;
  font: normal normal 600 12px Open Sans !important;
  font-weight: 500 !important;
}
.footer-table-printer {
  border: 1px solid var(--border-light-color) !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  height: 50px;
}

.printer-sales-order-button {
  font-size: 1.4rem;
  width: 200px;
  height: 40px;
  color: var(--main-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
}

.printer-sales-order-button-disabled {
  font-size: 1.4rem;
  width: 200px;
  height: 40px;
  color: var(--main-color);
  background-color: var(--inactive-color);
  border: none;
  border-radius: 5px;
}

.printer-filter-button {
  font-size: 2rem;
  width: 47px;
  height: 40px;
  color: var(--main-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
}

.printer-filter-options {
  position: relative;
  display: flex;
  top: -12px;
}

.printer-filters {
  position: absolute;
  width: 162px;
  background-color: var(--main-color);
  font-size: 12px;
  box-shadow: 0px 3px 6px var(--shadow-color);
  border-radius: 5px;
  z-index: 2;
}

.printer-filter-option {
  padding: 11px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.printer-filter-option input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.set-margin-left {
  margin-left: 18px !important;
}

.printer-table {
  width: 100%;
  min-width: 100%;
  margin-bottom: 0;
}

.printer-table.products {
  min-width: unset;
}

.printer-table th {
  padding: 6px 10px;
  border: 1px solid var(--border-light-color);
  background-color: #f8f9fa;
  color: #5b5b5b;
  opacity: 1;
}

.printer-table tr {
  font-size: 14px;
}

.hidde-border-bottom {
  border-bottom: none !important;
}

.printer-table td {
  padding: 6px 20px;
  border-left: 1px solid var(--border-light-color);
  border-right: 1px solid var(--border-light-color);
  border-bottom: none !important;
  margin-left: 20px !important;
  color: #5b5b5b !important;
  place-content: center;
  font: normal normal 600 12px Open Sans !important;
  font-weight: 500 !important;
}
tfoot tr,
.stickyFooter tr {
  border-top: 1px solid var(--border-light-color) !important;
  border-bottom: 1px solid var(--border-light-color) !important;
}

.printer-table thead {
  position: sticky;
  top: 0;
}

.printer-table .printer-actions {
  display: flex;
  gap: 22px;
  align-items: center;
}

.printer-table .printer-actions svg {
  cursor: pointer;
}

.tableContainer {
  border-bottom: 1px solid var(--border-light-color);
}

.tableContainer-sp {
  border-bottom: 1px solid var(--border-light-color);
}

.tableContainer-sp::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tableContainer::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.stickyFooter {
  background: #f8f9fa;
  border-top: 1px solid var(--border-light-color);
}

.product-resume__wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 21px;
}

.product-resume__wrapper .product-resume__item {
  padding: 21px 23px;
}

.remove-border-right {
  border-right: none !important;
}

.remove-border-left {
  border-left: none !important;
}
.cs-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  gap: 20px;
  width: fit-content;
}

.blod-font {
  font: normal normal 600 12px Open Sans !important;
  color: #000000 !important;
}

.show-cell-report {
  display: table-cell;
  vertical-align: inherit;
}

.hidde-cell-report {
  display: none !important;
}

.align-right {
  text-align: right;
}

.subtitle-credit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle-credit > * {
  font-weight: bold;
}

.label-bold {
  font-weight: bold;
}

.subtitle-dash {
  margin: 0 2px;
}

.printer-table-continue-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.printer-table-continue-button-wrapper .printer-header-button {
  font-size: 1.4rem;
  width: 200px;
  height: 40px;
  color: var(--main-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
}
.printer-table-continue-button-wrapper .printer-header-button:hover {
  opacity: 0.9;
}

.checkbox-wrapper-65 *,
.checkbox-wrapper-65 ::after,
.checkbox-wrapper-65 ::before {
  box-sizing: border-box;
}
.checkbox-wrapper-65 .cbx {
  position: relative;
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: #ffffff;
  background-image: white;
  border: 1px solid #d1d3d4;
  transition: all 0.15s ease;
}
.checkbox-wrapper-65 .cbx svg {
  position: absolute;
  top: 2px;
  left: 3px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #fff;
  stroke-width: 2;
  stroke-dasharray: 14;
  stroke-dashoffset: 14;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-65 .cbx + span {
  float: left;
  margin-left: 4px;
}
.checkbox-wrapper-65 {
  user-select: none;
}
.checkbox-wrapper-65 label {
  display: inline-block;
  cursor: pointer;
}
.checkbox-wrapper-65 input[type='checkbox'] {
  display: none;
  visibility: hidden;
}
.checkbox-wrapper-65 input[type='checkbox']:checked + .cbx {
  background-color: #ffffff;
  background-image: linear-gradient(#003082, #003082);
  border: none;
}
.checkbox-wrapper-65 input[type='checkbox']:checked + .cbx svg {
  stroke-dashoffset: 0;
  transition: all 0.15s ease;
}
