.companypicker-menu {
  background-color: var(--main-color);
  position: absolute;
  top: 68px;
  width: 200px;
  padding: 10px;
  box-shadow: 0px 3px 6px var(--shadow-color);
  text-align: center;
}
.companypicker-menu-off {
  display: none;
}

.companypicker-menu ul {
  list-style: none;
  padding: 0;
}

.company-element {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.company-element img {
  width: 40%;
}

.company-element p {
  font-size: 1.2em;
}
