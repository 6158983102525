.generic-closing-button {
  display: flex;
  justify-content: flex-end;
}

.generic-closing-button.modal-client {
  padding: 41px 30px 25px;
}

.modal-content-text {
  padding: 0 95px;
  text-align: center;
}

.modal-content-text h2 {
  font-size: 1.7rem;
  letter-spacing: 0.17px;
  line-height: 23px;
  font-weight: var(--semibold);
}

.modal-content-buttons {
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 1.9rem;
}

.modal-content-buttons button {
  width: 52px;
  height: 47px;
  border: none;
  border-radius: 5px;
  color: var(--secondary-color);
  background-color: var(--main-color);
  border: 1px solid var(--secondary-color);
}

.modal-content-buttons button:nth-child(1) {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.closing-button-less-padding {
  display: flex;
  justify-content: flex-end;
  padding: 0px 25px;
}

.modal-box-generic-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 408px;
  height: 276px;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.modal-box-generic-modal-caution {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 408px;
  height: auto;
  border-radius: 15px;
  background-color: white;
  padding-top: 28px;
  padding-bottom: 28px;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}
.modal-box-generic-modal-caution2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 502px;
  height: auto;
  border-radius: 15px;
  background-color: white;
  padding-top: 28px;
  padding-bottom: 28px;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}
.text1 {
  min-width: 100%;
}
