.leave-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 502px;
  height: 337px;
  border-radius: 15px;
  padding: 31px 24px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.leave-page-container {
  padding: 0 31px;
  text-align: center;
}

.leave-modal-close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.leave-page-header h2 {
  font-size: 1.9rem;
  letter-spacing: 0.38px;
  line-height: 26px;
  font-weight: var(--semibold);
  margin-bottom: 13px;
}

.leave-page-body {
  text-align: center;
}

.leave-page-body p {
  font-size: 1.3rem;
  font-weight: var(--semibold);
  letter-spacing: 0.26px;
  color: #7e7e7e;
}

.leave-page-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 32px;
}

.leave-page-buttons button {
  font-size: 1.9rem;
  width: 52px;
  height: 47px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}

.leave-page-buttons button:nth-child(1) {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.leave-page-buttons button:nth-child(2) {
  background-color: var(--main-color);
}
