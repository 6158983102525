.calculator {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99999999;
  overflow: scroll;
}

.calculator-closed {
  width: 13.5rem;
}

.calculator-top {
  display: flex;
  justify-content: flex-end;
}

.calculator-top-content {
  display: flex;
  gap: 15px;
  background-color: var(--secondary-color);
  padding: 9px 40px;
  border-radius: 5px 5px 0px 0px;
}

.calculator-choosable {
  background-color: var(--main-color);
  max-width: 700px;
  max-height: 600px;
  display: flex;
  overflow: hidden;
  padding: 23px 26px;
  box-shadow: 0px 1px 3px var(--shadow-color);
  border-radius: 5px 5px 0px 0px;
  transition: all 200ms linear;
}

.calculator-choosable-hidden {
  max-height: 0;
  overflow: hidden;
}

.calculator-option {
  box-shadow: 0px 1px 3px var(--shadow-color);
  border-radius: 5px 5px 0px 0px;
  padding: 15px 20px;
}

.calculator-option h2 {
  font-size: 1.8rem;
  letter-spacing: 0.18px;
  color: var(--secondary-color);
  margin-top: 7.5px;
}

.calculator-option:hover {
  background-color: var(--secondary-color);
}

.calculator-option:hover h2 {
  color: var(--main-color);
}

.calculator-pvc {
  background-color: var(--main-color);
  width: 631px;
  min-height: 444px;
  padding: 34px 37px;
  box-shadow: 0px 1px 3px var(--shadow-color);
  border-radius: 5px 5px 0px 0px;
  transition: height 200ms linear;
  overflow: scroll;
}

.calculator-pvc-hidden {
  max-height: 0;
  overflow: hidden;
}

.calculator-pvc-header {
  display: flex;
  color: var(--dark-color-1);
  font-size: 1.6rem;
  align-items: center;
  gap: 21px;
}

.calculator-pvc-header h2 {
  font-weight: var(--semibold);
}

.calculator-data {
  margin-top: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 33px;
}

.fa-arrow-right-arrow-left {
  font-size: 2.5rem;
}

.calculator-data input {
  padding: 16px 27px;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  font-size: 1.6rem;
  letter-spacing: 0.32px;
  line-height: 22px;
  color: var(--input-background-color);
}

.calculator-option:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(330deg)
    brightness(102%) contrast(103%);
}

.calculator-laminas {
  margin-top: 35px;
  display: flex;
  gap: 22px;
  align-items: center;
}

.calculator-laminas h2 {
  font-size: 1.6rem;
  letter-spacing: 0.16px;
}

.calculator-laminas-options {
  display: flex;
  gap: 8px;
}

.calculator-lamina-option label {
  border-radius: 5px;
  padding: 9px 17px;
  border: 1px solid var(--secondary-color);
  display: flex;
}

.calculator-lamina-option .selected {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.calculator-lamina-option input {
  display: none;
}

.calculator-lamina-option p {
  font-size: 1.4rem;
  color: var(--secondary-color);
}

.calculator-buttons {
  margin-top: 38px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.calculator-buttons button {
  padding: 13px 108px;
  font-size: 1.4rem;
  border-radius: 5px;
}

.calculator-buttons-limpiar {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  background-color: var(--main-color);
}

.calculator-buttons-calcular {
  border: 1px solid var(--secondary-color);
  color: var(--main-color);
  background-color: var(--secondary-color);
}

.calc-result-table h2 {
  margin-top: 28px;
  font-size: 1.6rem;
  letter-spacing: 0.16px;
  margin-bottom: 14px;
  font-weight: var(--semibold);
}

.calc-result-table td {
  font-size: 1.4rem;
  line-height: 19px;
  letter-spacing: 0.14px;
  padding: 7px 30px;
  color: var(--dark-color-1);
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .calculator-option {
    padding: 40px 29px;
  }
}
