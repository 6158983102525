.single-product-cart {
    width: 100%;
    margin-top: 12px;
  }
  
  .single-product-cart thead tr th {
    padding: 12px 21px;
    font-size: 14px;
    color: #5b5b5b;
    background-color: #f8f9fa;
    border: 1px solid var(--border-light-color);
    font-weight: var(--regular);
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1366px) {
    .single-product-cart thead tr th {
      font-size: 12px;
    }
  }
  
