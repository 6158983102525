.generic-multiple-address-search-filter {
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
}

.generic-multiple-address-filter-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1rem;
  height: 100%;
  padding: 10px 30px;
}

.generic-multiple-address-search-filter {
  display: flex;
  width: 100%;
  position: relative;
  height: 52px;
}

.generic-multiple-address-filter-options {
  position: absolute;
  left: 0;
  top: 52px;
  z-index: 1;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  max-height: 200px;
  padding: 0;
  overflow: scroll;
  transition: all 0.1s;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.generic-multiple-address-filter-options-hidden {
  max-height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  padding: 0;
  transition: all 0.1s;
}

.generic-multiple-address-filter-options
  .generic-multiple-address-filter-option:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
  font-weight: var(--semibold);
  cursor: pointer;
}

.generic-multiple-address-filter-option label {
  cursor: pointer;
  width: 100%;
}

.generic-multiple-address-filter-option .radio {
  display: none;
}

.generic-multiple-address-filter-option {
  font-size: 1.2rem;
  padding-left: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 40px;
}

.generic-multiple-address-label-placeholder {
  color: var(--placeholder-color);
  font-size: 15px;
}

.generic-multiple-address-label-text {
  font-size: 15px;
}

.generic-multiple-address-title-text {
  font-size: 15px;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 16px;
}

.generic-multiple-address-title-text-bold {
  font-size: 15px;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 16px;
  font-weight: bold;
}

.generic-multiple-address-father-container {
  margin: 0px;
}

.credit-extra-fields {
  margin-top: 39px;
}
