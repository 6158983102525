.product_sale_body {
  padding-top: var(--padding-top-bar);
  padding-left: var(--padding-left-bar);
  overflow: hidden;
}

.product_sale_container {
  height: calc(100vh - var(--padding-top-bar));
  margin: 0;
}

@media screen and (max-width: 992px) {
  .product_sale_container {
    height: 100%;
  }
}

.product-sale-info {
  background: var(--main-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--shadow-color);
  max-height: calc(100vh - var(--padding-top-bar));
  overflow-y: scroll;
  padding: 25px 23px;
}

.single-product-search {
  padding: 0;
}

.product-sale-search-bar {
  width: 100%;
  position: relative;
}

.product-sale-search-bar input {
  width: 100%;
  padding: 13px 40px 13px 23px;
  font-size: 14px;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  height: 45px;
}

.btn-customer-counted {
  font-size: 20px;
  width: 47px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  color: var(--main-color);
  background: var(--secondary-color);
}

.field-required-camp {
  color: red
}

.container-search-counted {
  height: 45px;
  display: flex;
  gap: 4px;
}

.product-sale-search-bar input:placeholder-shown {
  text-overflow: ellipsis;
}

.product-sale-search-bar input::-webkit-input-placeholder {
  color: #000000;
}

.product-sale-search-bar input::-moz-placeholder {
  color: #000000;
}

.product-sale-search-bar input:-ms-input-placeholder {
  color: #000000;
}

.product-sale-search-bar .seach-icon {
  position: absolute;
  top: calc(50% - 10px);
  right: 20px;
  cursor: pointer;
}
.product-sale-search-bar .seach-spinner {
  position: absolute;
  top: calc(50% - 12px);
  right: 20px;
}

.product-sale-client {
  margin-top: 18px;
}

.product-sale-client-header {
  color: var(--secondary-color);
  font-size: 16px;
  font-weight: var(--semibold);
}

.product-search-options {
  position: absolute;
  max-height: 400px;
  overflow-y: scroll;
  padding: 22px;
  width: 100%;
  box-shadow: 0px 3px 6px var(--shadow-color);
  border-radius: 0px 0px 5px 5px;
  background-color: var(--main-color);
}

.product-sale-client-form {
  margin-top: 9px;
  width: 100%;
}

.product-sale-client-form-element input {
  width: 100%;
  font-size: 14px;
  height: 45px;
  margin-bottom: 10px;
  padding-left: 21px;
  outline: none;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
}

.product-sale-client-see-more {
  display: flex;
  justify-content: flex-end;
}

.product-sale-client-see-more p {
  color: var(--secondary-color);
  text-decoration: underline;
}

.product-sale-client-see-more p:hover {
  cursor: pointer;
}

.product-sale-client-form-element label {
  margin-bottom: 3px;
  font-size: 12px;
}

.product-sale-delivery-method {
  margin-top: 19px;
}

.single-product-search {
  padding: 7px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  height: 100%;
  overflow: auto;
}

.product-sale-search-product,
.product-sale-cart {
  /* height: 50%; */
  flex-basis: 0 0 35%;
  /* min-height: 32vh; */
  /* max-height: 42vh; */
  padding: 18px;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 5px;
  background-color: var(--main-color);
}

.product-SummerOrder {
  /* height: 100%; */
  flex-basis: 0 0 35%;
  /* min-height: 32vh; */
  /* max-height: 42vh; */
  padding: 18px;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 5px;
  background-color: var(--main-color);
}

.product-SummerOrder h2 {
  color: var(--secondary-color);
  font-weight: var(--semibold);
  font-size: 16px;
  margin-bottom: 1rem;
}

.product-sale-cart h2 {
  color: var(--secondary-color);
  font-weight: var(--semibold);
  font-size: 16px;
  margin-bottom: 1rem;
}

.product-sale-button {
  max-height: 10vh;
  display: flex;
  justify-content: flex-end;
}

.product-sale-button .btn {
  font-size: 14px;
  border-radius: 5px;
  padding: 12px 54px;
  border: none;
  color: var(--main-color);
  background-color: var(--secondary-color);
}

.product-sale-button .btn:disabled {
  background-color: lightgray;
}

.products-cart-button-tooltip {
  border: 1px solid var(--secondary-color);
  border-color: var(--secondary-color);
  font-size: 1.3rem;
  padding: 11px 17px;
  border-radius: 5px;
  color: var(--secondary-color);
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  background-color: #ffff;
  font-size: 14px;
  font-family: 'Open Sans';
}

.products-cart-button-tooltip:enabled:hover {
  background-color: var(--secondary-color);
  color: #ffff;
}

.products-cart-button-tooltip:disabled {
  background-color: lightgray;
}

.searchBox-sp {
  width: 100%;
  height: 100%;
  outline: none;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  padding: 9px 18px;
  font-size: 14px;
}

.ais-SearchBox {
  width: 40%;
}

.ais-InfiniteHits-sentinel {
  width: 100%;
}

.hidden-button {
  display: none;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: none;
}

.client-option-sp {
  font-size: 14px;
  padding: 10px 0;
}
.client-option-sp:first-child {
  padding-top: 0;
}

.client-option-sp:last-child {
  padding-bottom: 0;
}

.client-option-sp:hover {
  font-weight: 600;
  color: var(--secondary-color);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .ais-InfiniteHits-item {
    width: calc(50% - 1rem);
  }
}

@media screen and (max-width: 480px) {
  .ais-InfiniteHits-item {
    width: 100%;
  }
}

.cantidad {
  border: none;
  width: 70px;
}

.cantidad::placeholder {
  color: #131523;
  opacity: 1;
}

.product-sale-client .content-form-direccion {
  padding: 0;
}
.product-sale-client .form-group-select {
  grid-template-columns: 1fr;
}
.product-sale-client .group-inputs-casa-phone {
  grid-template-columns: 1fr;
}

.product-sale-client .address-options {
  display: flex;
  flex-direction: column;
}

.product-sale-client .domicilio-option.add-domicilio {
  grid-column-start: 1;
  grid-column-end: -1;
}

.texto-Summary-Order {
  /* float: right; */
  text-align: left;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0.5cm;
  margin-left: 2rem;
  color: rgb(34, 127, 233);
}

.img-Close-Summary-Order {
  float: right;
  text-align: left;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  color: rgb(34, 127, 233);
  margin-right: 1rem;
}


@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .ais-SearchBox {
    width: 50%;
  }
}
