.inventory-topbar {
  background-color: #f8f9fa;
  border: 1px solid var(--border-light-color);
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.inventory-search {
  width: 600px;
  position: relative;
}

.inventory-search .inv-search-icon {
  position: absolute;
  top: 13px;
  right: 18px;
  width: 2.5%;
  cursor: pointer;
}

.scrollable {
  overflow-x: scroll;
}
.inventory-table thead tr th,
.action-table thead tr th {
  font-weight: var(--regular);
}

.inventory-sales-order-button {
  font-size: 1.4rem;
  width: 200px;
  height: 40px;
  color: var(--main-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
}

.inventory-sales-order-button-disabled {
  font-size: 1.4rem;
  width: 200px;
  height: 40px;
  color: var(--main-color);
  background-color: var(--inactive-color);
  border: none;
  border-radius: 5px;
}

.inventory-filter-button {
  font-size: 2rem;
  width: 47px;
  height: 40px;
  color: var(--main-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
}

.inventory-filter-options {
  position: relative;
  display: flex;
}

.inventory-filters {
  position: absolute;
  width: 162px;
  background-color: var(--main-color);
  font-size: 12px;
  box-shadow: 0px 3px 6px var(--shadow-color);
  border-radius: 5px;
  z-index: 2;
}

.inventory-filter-option {
  padding: 11px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inventory-filter-option input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.inventory-table {
  width: 100%;
  min-width: 1134px;
}

.inventory-table.products {
   min-width: unset;
}

.inventory-table th {
  font-size: 14px;
  padding: 12px 23px;
  border: 1px solid var(--border-light-color);
  border-top: none;
  background-color: #f8f9fa;
}

.inventory-table tr {
  font-size: 14px;
  border-bottom: 1px solid var(--border-light-color);
}

.hidde-border-bottom {
  border-bottom: none !important;
}

.inventory-table td {
  padding: 21px 23px;
  border: 1px solid var(--border-light-color);
  border-bottom: none;
  border-top: none;
}

.inventory-table thead {
  position: sticky;
  top: 0;
}

.inventory-table .inventory-actions {
  display: flex;
  gap: 22px;
  align-items: center;
}
.inventory-table .inventory-actions svg {
  cursor: pointer;
}

.tableContainer {
  height: 50vh;
  overflow-y: scroll;
  position: relative;
  border-bottom: 1px solid var(--border-light-color);
}

.tableContainer-sp {
  max-height: 30vh;
  position: relative;
  border-bottom: 1px solid var(--border-light-color);
}

.tableContainer-sp::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tableContainer::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.stickyFooter {
  position: sticky;
  bottom: 0;
  background: #f8f9fa;
  border-top: 1px solid var(--border-light-color);
}

.product-resume__wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 21px;
}
.product-resume__wrapper .product-resume__item {
  padding: 21px 23px;
}

.inventory-search input {
  padding: 10px 18px;
  font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {

  .inventory-table td {
    padding: 21px 15px;
  }
  .inventory-table tr {
    font-size: 12px;
  }
  .inventory-table th {
    font-size: 12px;
    padding: 21px 15px;
  }
}

.switchContainer {
  margin-left: 2rem;
  margin-top: 8px;
}
.textSwitchContainer {
  font-size: 15px;
  margin-top: 9px;
  margin-left:10px ;
}
