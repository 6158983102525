.salesman-navbar {
  height: 100vh;
  z-index: 99999999;
  background-color: var(--main-color);
  position: fixed;
  right: 0;
  top: 0;
  transition: 200ms linear;
  padding: 36px 19px;
  width: 500px;
  box-shadow: -4px 0px 12px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -4px 0px 12px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -4px 0px 12px -4px rgba(0, 0, 0, 0.5);
}

.salesman-navbar-closed {
  width: 0;
}

.close-salesman {
  display: flex;
  justify-content: space-between;
}

.close-salesman:hover {
  cursor: pointer;
}

.salesman-title {
  font-size: 1.47vw;
  color: var(--secondary-color);
}

.search-bar-salesman {
  width: 100%;
  height: 40px;
}

.search-bar-salesman input {
  width: 100%;
  height: 100%;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  padding: 10px 18px;
  font-size: 16px;
  letter-spacing: 0.32px;
  outline: none;
}
.salesman-list {
  height: calc(100vh - 150px);
  overflow-y: scroll;
}

.change-salesman-button {
  width: 100%;
  font-size: 14px;
  outline: none;
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-color);
  color: var(--main-color);
  padding: 13px 0;
  border-radius: 5px;
}

.salesman-list ul {
  list-style: none;
  margin-top: 25px;
}

.salesman-list ul li {
  display: flex;
  gap: 10px;
  align-items: center;
}

.salesman-list ul li label {
  font-size: 14px;
  margin: 10px 0;
}
