.crear-client-content {
  padding: 5%;
  padding-left: 10%;
  padding-right: 10%;
}

.crear-client-main {
  margin: 0 auto;
  font-size: 1.75rem;
}

/*tab */
.myClass {
  padding-left: 0px;
  padding-bottom: 40px;
  border-bottom: none !important;
}

.myClass .nav-link {
  color: var(--inactive-color) !important;
  width: 202px;
  height: 44px;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.myClass .nav-link.active {
  color: white !important;
  background-color: var(--secondary-color);
  border-color: #dee2e6 #dee2e6 #fff;
}

.form-area {
  padding-bottom: 15px;
  display: flex;
}

.form-area .row {
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  background: var(--main-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  opacity: 1;
  padding: 10px 25px;
  font-size: 1.5rem;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

.form-control:focus {
  color: #475f7b;
  background-color: #fff;
  border-color: #5a8dee;
  outline: 0;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}

.label-radio {
  font-size: 1.5rem;
  padding-left: 10px;
}

input[type='radio'] {
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-clip: content-box;
  padding: 1px;
  border: 2px solid #bebebe;
}

input[type='radio']:checked {
  background-color: var(--secondary-color);
  border: 2px solid #bebebe;
}

.custom-control-label {
  font-size: 1.3rem;
  padding-left: 10px;
}

.section-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 130px;
}

.btn.btn-primary.form-submit.buttons-save {
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  opacity: 1;
  color: #fff;
  padding: 13px 18px;
  font-size: 14px;
  min-width: 245px;
  max-width: 287px;
  height: 44px;
}
.btn.btn-primary.form-submit.buttons-save:disabled {
  background: var(--disabled);
  border: 1px solid var(--disabled);
}

.btn.btn-primary.form-submit.buttons-save-toadd {
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  opacity: 1;
  color: var(--main-color);
  padding: 13px 18px;
  font-size: 14px;
  min-width: 245px;
  max-width: 287px;
  height: 44px;
}
.btn.btn-primary.form-submit {
  display: grid;
  place-content: center;
}

.form-group {
  position: relative;
  /* margin-bottom: 15px; */
  display: flex;
}
.custom-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
}
.input-feedback {
  color: red;
  line-height: 14px;
  min-height: 10px;
  margin-bottom: 10px;
}

.input-feedback strong {
  font-size: 1.2rem;
}

input.error {
  border: 1px solid red !important;
}

.custom-control-input-error {
  border: 1px solid red !important;
}

.modal-box-address {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px;
  width: 500px;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.client-add-address {
  width: 100%;
  height: 54px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.client-map-continue-button {
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.generic-price-list-label-text {
  font-size: 12px;
  color: var(--dark);
  border-radius: 20px;
  background-color: lightgray;
  margin-left: 3px;
  padding-left: 6px;
}

.generic-price-list-label-text-unselect {
  background-color: white;
  color: #60656a;
}

.icon-list-prices {
  border-radius: 14px;
  border: solid 1px;
  margin: 6px;
  padding: 1px;
  width: 11px;
}

.item-list-price-li {
  padding: 4px 10px 4px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.checkbox-item-list-price {
  cursor: pointer;
}
.label-item-list-price {
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

.list-price-search-icon {
  cursor: pointer;
  position: absolute;
  right: 22px;
  width: 18px;
}

.item-list-price-li-search {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 4px 1px;
  position: relative;
}

.item-search-box-list-price {
  background-color: #f3f3f3;
  height: 44px;
  margin-top: -20px;
  position: sticky;
  top: 0;
}

.ul-price-list {
  top: 45px;
  left: -2px !important;
}

.list-prices-component {
  height: 44px !important;
}

.list-price-search {
  width: 100%;
  padding: 12px 18px;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  font-size: 15px;
  color: #7e7e7e;
  outline: none;
  height: 36px !important;
}

.generic-address-filter-content-list-price {
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
  font-size: 1rem;
  height: 100%;
  padding: 10px 30px;
  border: none;
  background-color: transparent;
}

.generic-list-price-down-icon {
  position: absolute;
  right: 35.7px;
  color: #7e7e7e;
}

.generic-list-price-down-icon.custom-icon {
  top: 9px;
  font-size: 1.7em !important;
  width: 16px;
}

.select-custom {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.customer-filter-option{
  position: relative;
  left: 0px;
  font-size: 15px;
  background-color: var(--main-color);
  border-radius: 0 0 5px 5px;
  list-style: none;
  padding-left: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* transition: all .1s; */
  width: 100%;
  z-index: 1;
}

.customer-filter-option-multiple {
  position: relative;
  left: 0px;
  font-size: 15px;
  background-color: var(--main-color);
  list-style: none;
  padding: 4px 0px 4px 32px;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}
.customer-filter-option:hover,
.customer-filter-option-multiple:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
}
.ul-filter-option-one {
  height: 50px;
}
.ul-filter-option-two {
  height: 100px;
}
.ul-filter-option-multiple {
  height: fit-content;
}

/* address checkbox */
.address-requiered-question-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;

}
.address-checkbox-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 8px 0 12px 0;
  gap: 16px;
}