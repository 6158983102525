.table-sell-order-topbar {
  background-color: #f8f9fa;
  border: 1px solid var(--border-light-color);
  padding: 25px;
  display: flex;
  min-width: 1134px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.table-sell-order-search {
  width: 600px;
  position: relative;
}

.table-sell-order-search .table-sell-order-search-icon {
  position: absolute;
  top: 13px;
  right: 18px;
  width: 2.5%;
}

.table-sell-order-table-style thead tr th,
.action-table thead tr th {
  font-weight: var(--semibold);
}

.table-sell-order-filter-button {
  font-size: 2rem;
  width: 47px;
  height: 40px;
  color: var(--main-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
}

.table-sell-order-filter-options {
  position: relative;
}

.table-sell-order-filtes {
  position: absolute;
  width: 162px;
  background-color: var(--main-color);
  font-size: 12px;
  box-shadow: 0px 3px 6px var(--shadow-color);
  border-radius: 5px;
  z-index: 2;
}

.table-sell-filter-option {
  padding: 11px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-sell-filter-option input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.table-sell-order-table-style {
  width: 100%;
  min-width: 1134px;
}

.table-sell-order-table-style th {
  font-size: 14px;
  padding: 12px 14px;
  border: 1px solid var(--border-light-color);
  border-top: none;
  background-color: #f8f9fa;
}

.table-sell-order-table-style tr {
  font-size: 14px;
  border-bottom: 1px solid var(--border-light-color);
}

.table-sell-order-table-style td {
  padding: 21px 23px;
  border: 1px solid var(--border-light-color);
  border-bottom: none;
  border-top: none;
}

.table-order-check-style {
  justify-content: center;
  display: flex;
}

.table-sell-order-table-style thead {
  position: sticky;
  top: 0;
}

.tableContainer {
  height: 50vh;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  border-bottom: 1px solid var(--border-light-color);
}

.tableContainer-sp {
 max-height: 30vh;  
  overflow-y: scroll;
  position: relative;
  border-bottom: 1px solid var(--border-light-color);
}

.tableContainer-summaryOrder {
  /* min-height: 80vh;  */
  height: 100%;  
   /* overflow-y: scroll; */
   position: relative;
   border-bottom: 1px solid var(--border-light-color);
 }

.stickyFooter {
  position: sticky;
  bottom: 0;
  background: #f8f9fa;
  border-top: 1px solid var(--border-light-color);
}

.product-resume__wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 21px;
}
.product-resume__wrapper .product-resume__item {
  padding: 21px 23px;
}

.arrow-order-style {
  margin-left: 9px;
}

.order-header-table-container {
  display: flex;

  justify-content: space-between;
}

.order-header-text {
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
}
.order-table-text {
  font-size: 14px;
}

.table-sell-order-directdel-container {
  width: 35%;
  flex-direction: column;
  display: flex;
}

.table-sell-order-directdel-column {
  display: flex;
  flex-direction: column;
}

.table-sell-order-directdel-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.table-sell-order-directdel-row-parent {
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.table-sell-order-option-span {
  margin-left: 9px;
  font-size: 16px;
}

.table-sell-order-option-select-title {
  font-size: 16px;
  font-weight: bold;
}

.table-sell-order-option-select-description {
  margin-top: 7px;
  font-size: 13px;
  color: var(--inactive-color-dark);
}

.table-sell-order-option-select-description-important {
  margin-top: 7px;
  font-size: 13px;
  font-weight: bold;
}

.table-sell-order-inventory-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-sell-order-trash-icon-size {
  color: #f64e60;
  font-size: 1.5rem;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .tableContainer-sp {
    max-height: 38vh;
  }
}

.single-product-cart {
  width: 100%;
  margin-top: 12px;
}

.single-product-cart thead tr th{
  padding: 12px 21px;
  font-size: 14px;
  color: #5b5b5b;
  background-color: #f8f9fa;
  border: 1px solid var(--border-light-color);
  font-weight: var(--regular);
}

.single-product-cart-a tbody tr td {
  padding: 12px 21px;
  font-size: 14px;
  color: #5b5b5b;
  background-color: #f8f9fa;;
  border: 1px solid var(--border-light-color);
  font-weight: var(--regular);
}

.single-product-cart-a > img {
  width: 21px;
  height: 21px;
  object-fit: contain;
}

.inventory-table {
  width: 100%;
  min-width: 1134px;
}

.inventory-table.products {
   min-width: unset;
}

.bottons-position {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.botton-disapproved {
  background: #ffff;
  border: 1px solid #2b1179;
  color: #2b1179;
  font-size: 1.5rem;
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 10px;
}

.botton-approved {
  background: #2b1179;
  color: #ffff;
  font-size: 1.5rem;
  padding: 10px 20px;
  border-radius: 10px;
}

.title-center {
  display: flex;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
}

.edit-pending {
  width: 100%;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
}

.edit-pending-span {
  position: relative;
}

.edit-pending-buttons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2.5rem;
}

.generic-closing-button.modal-pending {
  padding: 20px 0px;
}

.modal-content-penging {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-content-penging>img {
  width: 50px;
}
