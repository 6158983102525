.product-cart-product-numbers {
  align-items: center;
  justify-content: space-between;
}

.modal-clase-price-change {
  position: absolute;
  margin: 0;
}

.modal-clase-price-change .modal-dialog {
  margin: 0;
  max-width: 100%;
}

.modal-clase-price-change .modal-content {
  border: none;
  border-radius: 0 !important;
  width: 100%;
  align-items: center;
  background-color: transparent;
}

.productos-cotizacion-left .modal-backdrop {
  width: 100% !important;
  position: absolute;
}

.cart-card {
  border-bottom: 1px solid var(--border-light-color);
  padding: 19px 15px 33px 15px;
}

.product-card-top-content {
  margin-bottom: 20px;
}

.square-pen-edit {
  cursor: pointer;
  z-index: 10;
}
