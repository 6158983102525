.icon-margin-cost {
    margin-left: 10px;
}

.cart-card-margin {
    display: flex;
    align-items: center;
}

  .secondary-color {
    color: var(--secondary-color);
  }