.modal-box-add-client {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 408px;
  height: 276px;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.add-modal-container {
  padding: 45px 17.5px 42px 17px;
}

.close-modal {
  font-size: 20px;
}

.close-modal:hover {
  cursor: pointer;
}

.add-client-modal-header {
  text-align: right;
  padding: 0 9px;
  margin-bottom: 10px;
}

.success-add-client-message {
  text-align: center;
  padding: 0 24px;
}

.success-add-client-message img {
  width: 15%;
  margin-bottom: 9px;
}

.success-add-client-message .confirmation-text-delete {
  font-size: 1.7em;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.17px;
  color: #010101;
  opacity: 1;
  text-align: center;
  margin: 3px 0;
}
