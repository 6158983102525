.divider {
  width: 100%;
  height: 1px;
  background-color: #f3f3f3;
  margin: 14px 0 14px 0;
}
.printer-preview-wrapper {
  padding-top: var(--padding-top-bar);
  padding-left: var(--padding-left-bar);
  display: grid;
  grid-template-columns: 1.4fr 0.6fr;
  background: white;
  height: 100vh;
  max-height: 100vh;
}

.printer-preview-left-wrapper {
  position: relative;
  padding: 16px 20px;
  width: 100%;
}
.printer-preview-left-title {
  position: absolute;
  left: 20px;
  top: 20px;
  font-weight: 600;
}
.printer-preview-left-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.printer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  display: none;
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.printer-overlay .printer-overlay-text {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--secondary-color);
}
.printer-preview-left-canvas-square-3,
.printer-preview-left-canvas-square-2,
.printer-preview-left-canvas {
  position: relative;
  width: 650px;
  /* width: 800px; */
  margin: -50px auto 0 auto;
  background-color: white;
  margin: auto;
  box-shadow: 6px 6px 0 0 rgba(0, 0, 0, 0.1);
}
.printer-preview-left-content-body {
  position: absolute;
  display: flex;
  gap: 4px;
  flex-direction: column;
  top: 130px;
  left: 20px;
  width: calc(100% - 40px);
}
.printer-preview-left-content-body-sku,
.printer-preview-left-content-body-price,
.printer-preview-left-content-body-title {
  font-size: 26px;
  line-height: 34px;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-body-sku {
  font-weight: 400;
  font-size: 18px;
}
.printer-preview-left-content-body-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.printer-preview-mini-text {
  font-size: 45px;
  font-weight: bold;
  color: red;
  margin-top: -30px;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-body-price {
  color: red;
  font-size: 134px;
  margin-top: 30px;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
}
.printer-preview-left-content-footer {
  position: absolute;
  bottom: 10px;
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(100% - 40px);
}
.printer-preview-left-content-footer-quotes {
  display: grid;
  grid-template-columns: 0.8fr 1.1fr 1.1fr;
}
.printer-preview-left-content-footer-quote-text {
  font-size: 34px;
  font-weight: bold;
  color: red;
  line-height: 34px;
  place-content: center;
  text-align: center;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-footer-legal {
  font-size: 10px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-content-footer-input-wrapper {
  width: 100%;
  /* background-color: #003082; */
  position: relative;
}
.printer-preview-content-pencil {
  /* position: absolute;
  top: -16px;
  right: 0; */
  font-size: 16px;
}
.printer-preview-left-content-footer-legal-2,
.printer-preview-left-content-footer-legal-3,
.printer-preview-left-content-footer-legal-1 {
  font-size: 10px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  border-radius: 4px;
  border-color: #d5d5d5;
  background-color: white;
  font-family: sans-serif;
  letter-spacing: normal;
  margin-bottom: 6px;
}
.printer-preview-left-content-footer-legal-3,
.printer-preview-left-content-footer-legal-2 {
  font-size: 6px;
  line-height: 6px;
}
.printer-terms-edit-wrapper-1 {
  display: flex;
  gap: 4px;
  position: absolute;
  top: -18px;
  right: 0;
  font-size: 16px;
  color: #003082;
  cursor: pointer;
}
.printer-preview-left-image {
  width: 100%;
  opacity: 1;
}
.printer-preview-left-indicator-badge-wrapper {
  position: absolute;
  bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.printer-preview-left-indicator-badge-plus,
.printer-preview-left-indicator-badge {
  width: 30px;
  height: 30px;
  background: #afafaf;
  border-radius: 50%;
  color: white;
  place-content: center;
  text-align: center;
  font-size: 18px;
  border: none;
  outline: none;
}
.printer-preview-left-indicator-badge-plus {
  background: #003082;
  font-size: 20px;
}
.printer-preview-left-indicator-badge-plus:disabled {
  background: #afafaf;
  color: white;
}
.printer-preview-page-indicator-wrapper {
  position: relative;
  z-index: 1;
}
.printer-preview-page-indicator-wrapper:hover
  .printer-preview-left-indicator-badge-minus {
  display: flex;
}
.printer-preview-left-indicator-badge-minus {
  border: none;
  outline: none;
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #fff;
  border: 1px solid #afafaf;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  padding: 6px;
  z-index: 2;
  display: none;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 14px;
}
.printer-preview-left-indicator-badge-minus:hover {
  background-color: #a0a0a0;
  color: white;
  border: 1px solid white;
}

.printer-preview-right-wrapper {
  position: relative;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 16px 20px;
  padding-bottom: 80px;
  height: calc(100vh - var(--padding-top-bar));
  max-height: calc(100vh - var(--padding-top-bar));
  overflow: scroll;
}
.printer-preview-right-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--secondary-color);
}
.printer-preview-right-product-wrapper {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 0.4fr 3.6fr;
  gap: 16px;
  margin-bottom: 4px;
  border: 1px solid #f3f3f3;
  padding: 8px 12px;
  border-radius: 5px;
  width: 100%;
}
.printer-page-indicator-badge {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background: #afafaf;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
}
.printer-page-indicator-badge.active {
  background: #ec1c24;
}
.printer-preview-right-product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.printer-preview-right-product-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.printer-preview-right-product-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.printer-preview-right-product-info-price {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.printer-preview-quantity-wrapper {
  width: 100%;
  max-width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}
.printer-preview-quantity-input {
  outline: none;
  background: white;
  font-size: 14px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #bebebe;
  text-align: center;
}
.printer-preview-quantity-button {
  border: none;
  outline: none;
  background: white;
  font-size: 14px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #bebebe;
}
.printer-preview-right-product-text-bold,
.printer-preview-right-product-text-title,
.printer-preview-right-product-text {
  width: 100%;
  font-size: 14px;
  font-weight: thin;
}
.printer-preview-right-product-text-title {
  height: 24px;
  text-overflow: ellipsis ellipsis;
  overflow: hidden;
}
.printer-preview-right-product-text-bold {
  font-weight: 600;
  color: var(--secondary-color);
}

.printer-preview-right-templates-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-wrap: wrap;
  padding-top: 6px;
  gap: 8px;
  position: relative;
}
.printer-modal-content-option-plus {
  border: none;
  outline: none;
  border-radius: 100%;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  background: white;
  gap: 5px;
  position: absolute;
  top: 25px;
  right: 0;
}
.printer-modal-content-option-plus:hover {
  transform: scale(1.06);
  transition: transform 0.2s;
}

.printer-finish-button-wrapper {
  position: fixed;
  bottom: 0;
  width: calc((100vw - var(--padding-left-bar)) * 0.3);
  right: 0;
  right: 0;
  padding: 0 20px;
  background-color: white;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.printer-finish-button {
  background: var(--secondary-color);
  color: white;
  border: none;
  outline: none;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
}

.printer-preview-more-text-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.printer-preview-more-text-wrapper .printer-preview-more-text {
  font-size: 12px;
  color: var(--secondary-color);
  cursor: pointer;
}

/* SQUARE-2 */
.printer-preview-left-canvas-square-2 {
  width: 450px;
  margin: -50px auto 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
}
.printer-preview-left-canvas-square-2-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* z-index: 44;
  background: yellow;
  width: 450px; */
  /* margin: -50px auto 0 auto; */
}
.printer-preview-left-content-body-square-2-2,
.printer-preview-left-content-body-square-2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 70px;
  left: 16px;
  width: calc(100% - 30px);
}
.printer-preview-left-content-body-square-2-2 {
  top: 75px;
}
.printer-preview-left-content-body-square-2-sku-2,
.printer-preview-left-content-body-square-2-price-2,
.printer-preview-left-content-body-square-2-title-2,
.printer-preview-left-content-body-square-2-sku,
.printer-preview-left-content-body-square-2-price,
.printer-preview-left-content-body-square-2-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-body-square-2-sku-2,
.printer-preview-left-content-body-square-2-sku {
  font-weight: 400;
  font-size: 14px;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-body-square-2-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.printer-preview-mini-text-square-2-2,
.printer-preview-mini-text-square-2 {
  font-size: 30px;
  font-weight: bold;
  color: red;
  margin-top: -24px;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-body-square-2-price-2,
.printer-preview-left-content-body-square-2-price {
  color: red;
  font-size: 90px;
  margin-top: 24px;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-footer-square-2-2,
.printer-preview-left-content-footer-square-2 {
  position: absolute;
  top: calc(50% - 82px);
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 30px);
}
.printer-preview-left-content-footer-square-2-2 {
  top: calc(100% - 82px);
}
.printer-preview-left-content-footer-square-2-quotes {
  display: grid;
  grid-template-columns: 0.6fr 1.3fr 1.1fr;
}
.printer-preview-left-content-footer-square-2-quote-text {
  font-size: 22px;
  font-weight: bold;
  color: red;
  line-height: 20px;
  place-content: center;
  text-align: center;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-footer-square-2-legal {
  font-size: 6px;
  line-height: 6px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  font-family: sans-serif;
  letter-spacing: normal;
}

/* SQUARE-3 */
.printer-preview-left-canvas-square-3 {
  width: 520px;
  margin: -50px auto 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  position: relative;
}
.printer-preview-left-canvas-square-3-box {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 50%; */
  width: 100%;
  /* background: yellow; */
  /* z-index: 44;
  width: 450px; */
  /* margin: -50px auto 0 auto; */
}
.printer-preview-left-content-body-square-3-2,
.printer-preview-left-content-body-square-3 {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 70px;
  left: 16px;
  width: calc(50% - 30px);
  gap: 4px;
}
.printer-preview-left-content-body-square-3-2 {
  top: 75px;
}
.printer-preview-left-content-body-square-3-sku-2,
.printer-preview-left-content-body-square-3-price-2,
.printer-preview-left-content-body-square-3-title-2,
.printer-preview-left-content-body-square-3-sku,
.printer-preview-left-content-body-square-3-price,
.printer-preview-left-content-body-square-3-title {
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-body-square-3-sku-2,
.printer-preview-left-content-body-square-3-sku {
  font-weight: 400;
  font-size: 13px;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-body-square-3-price-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.printer-preview-mini-text-square-3-2,
.printer-preview-mini-text-square-3 {
  font-size: 22px;
  font-weight: bold;
  color: red;
  margin-top: -8px;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-body-square-3-price-2,
.printer-preview-left-content-body-square-3-price {
  color: red;
  font-size: 70px;
  margin-top: 24px;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-footer-square-3-2,
.printer-preview-left-content-footer-square-3 {
  position: absolute;
  top: calc(50% - 96px);
  left: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(50% - 10px);
}
.printer-preview-left-content-footer-square-3-2 {
  top: calc(100% - 92px);
}
.printer-preview-left-content-footer-square-3-quotes {
  display: grid;
  grid-template-columns: 0.8fr 1.1fr 1.1fr;
}
.printer-preview-left-content-footer-square-3-quote-text {
  font-size: 18px;
  font-weight: bold;
  color: red;
  line-height: 18px;
  place-content: center;
  text-align: center;
  font-family: sans-serif;
  letter-spacing: normal;
}
.printer-preview-left-content-footer-square-3-legal {
  font-size: 6px;
  line-height: 6px;
  font-weight: 400;
  color: black;
  border: none;
  outline: none;
  resize: none;
  font-family: sans-serif;
  letter-spacing: normal;
}

.to-print-elements {
  display: none;
}

.span-text-with-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 82px;
}
