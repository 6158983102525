.customer-info-navbar {
  height: 100vh;
  z-index: 99999999;
  background-color: var(--main-color);
  position: fixed;
  right: 0;
  top: 65px;
  transition: 200ms linear;
  padding: 36px 19px;
  width: 500px;
  box-shadow: -4px 0px 12px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -4px 0px 12px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -4px 0px 12px -4px rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
}

.customer-info-navbar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.customer-info-navbar-closed {
  width: 0;
}

.customer-info-sidebar-container {
  padding: 0 10px;
}

.customer-info-sidebar-element {
  margin-bottom: 12px;
}

.customer-info-sidebar-element h2 {
  font-size: 12px;
  letter-spacing: 0px;
  color: #010101;
}

.customer-info-sidebar-element p {
  font-size: 16px;
  letter-spacing: 0px;
  color: var(--secondary-color);
}

.customer-info-sidebar-address {
  font-size: 14px;
  display: flex;
  gap: 11px;
  padding: 17px 24px;
  border: 1px dashed #0055b8;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
.customer-info-sidebar-address > p {
  max-width: 14ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.customer-info-sidebar-addresses {
  padding: 10px 0;
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}

.see-more-customer {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  color: var(--secondary-color);
  cursor: pointer;
}

.customer-info-extra {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 65px;
}

.customer-info-extra-element {
  flex-basis: 50%;
  text-align: center;
  font-size: 14px;
  padding: 24px 49px;
  border: 2px solid var(--border-light-color);
  border-radius: 5px;
}

.customer-info-extra-element p {
  margin-top: 12px;
  font-weight: 600;
}
.customer-info-extra-element.actionable {
  cursor: pointer;
  outline: none;
  background: white;
}

.customer-info-extra-element.actionable:hover {
  border: 2px solid var(--secondary-color);
}

.journal-icons {
  width: 60px;
  height: 60px;
}
