.summary-customer-cart {
    margin-top: 10px;
    background-color: var(--main-color);
    padding: 20px 17px;
    box-shadow: 0px 1px 3px #15223214;
    border-radius: 5px 5px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.shopping-address-display .icons div,
.summary-customer-cart .icons div {
  background-color: #fbfbfb;
  padding: 11px 19px;
  border-radius: 5px;
}
  .summary-customer-cart-icons {
    display: flex;
    gap: 10px;
    margin-right: 27px;
    align-items: center;
  }
  
  .summary-customer-cart-items {
    width: 100%;
  }
  
  .summary-customer-cart-items h4 {
    font-size: 0.87vw;
  }
  
  .shopping-address-display .icons,
  .summary-customer-cart .icons {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 1.8rem;
  }
  
  .shopping-address-display .icons div,
  .summary-customer-cart .icons div {
    background-color: #fbfbfb;
    padding: 11px;
    border-radius: 5px;
  }
  
  .shopping-address-display .icons div:hover,
  .summary-customer-cart .icons div:hover {
    cursor: pointer;
  }
  
  .shopping-address-display .icons div:nth-child(1),
  .summary-customer-cart .icons div:nth-child(1) {
    color: #3699ff;
  }
  
  .shopping-address-display .icons div:nth-child(2),
  .summary-customer-cart .icons div:nth-child(2) {
    color: #fb5c3c;
  }
  
  .product-summary-cart-total-numbers {
    text-align: right;
    margin-right: 25px;
  }
  
  .product-summary-cart-total-numbers p {
    font-size: 0.83vw;
  }

  .total {
    background-color: var(--main-color);
  }
  
  .container-summary {
    padding-top: 1rem;
    text-align: left;
    font-weight: bold;
    font-weight: var(--semibold);
    letter-spacing: 0.36px;
  }
  
  .container-summary h2 {
    font-weight: bold;
    font-weight: var(--semibold);
    margin-bottom: 30px;
    font-size: 13px;
    letter-spacing: 0.36px;
  }
  
  .container-summary p {
    margin-bottom: 0.1rem;
    font-size: 13px;
  }
  
  .product-summary-total {
    display: flex;
    justify-content: space-between;
  }
  .product-summary-totals {
    justify-content: space-between;
    margin-top: 15px;
    font-size: 0.83vw;
    padding: 0 18px;
    row-gap: 2rem;
  }
  .product-summary-total-numbers p {
    text-align: right;
    font-size: 13px;
  }
  
  .product-summary-total-text p {
    font-size: 13px;
  }
  
  .btn-container {
    justify-content: center;
    align-items: center;
    padding: 25px;
    width: 100%;
  }
  
  .btn-action-create-quotationV1 {
    font-size: 14px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 6px;
    border-radius: 5px;
    color: #afafaf;
    width: 100%;
  }
  .products-cart-button-tooltip-filled:disabled {
    background-color: lightgray;
  }
  
  .btn-action-create-quotationV1:enabled {
    background-color: var(--secondary-color);
    color: var(--main-color);
  }
  .product-image {
    max-width: 70px;
    height: auto;
  }