.cancel-dispatch-modal-box-generate-sales-order {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: auto;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}
.cancel-dispatch-modal-box-generate-sales-order-container {
  padding: 30px;
}

.cancel-dispatch-close-modal {
  font-size: 20px;
}

.cancel-dispatch-close-modal:hover {
  cursor: pointer;
}

.cancel-dispatch-confirmation-header-modal {
  text-align: right;
  padding: 0 9px;
  margin-bottom: 10px;
}

.cancel-dispatch-confirmation-header {
  text-align: right;
  padding: 0 9px;
  margin-bottom: 10px;
}

.cancel-dispatch-modal-box-generate-sales-order .cancel-dispatch-modal-buttons {
  margin-top: 25px;
  display: flex;
  padding: 0;
  gap: 15px;
  justify-content: end;
}

.cancel-dispatch-modal-box-generate-sales-order
  .cancel-dispatch-modal-buttons
  .cancel-dispatch-btn {
  font-family: inherit;
  outline: none;
  font-size: 1.7rem;
  padding: 12px 20px;
  border-radius: 5px;
}

.cancel-dispatch-modal-box-generate-sales-order
  .cancel-dispatch-modal-buttons
  .cancel-dispatch-btn.cancel-dispatch-modal-si {
  background-color: var(--secondary-color);
  color: var(--main-color);
  border: none;
  padding: 12px 46px;
}

.cancel-dispatch-modal-box-generate-sales-order
  .cancel-dispatch-modal-buttons
  .cancel-dispatch-btn.cancel-dispatch-modal-no {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}

.cancel-dispatch-confirmation-message {
  text-align: center;
  padding: 0 24px;
}

.cancel-dispatch-confirmation-message img {
  width: 15%;
  margin-bottom: 9px;
}

.cancel-dispatch-confirmation-message .cancel-dispatch-confirmation-text-delete {
  font-size: 1.7em;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.17px;
  color: #010101;
  opacity: 1;
  text-align: center;
  margin: 3px 0;
}

.cancel-dispatch-form-field-title {
  font-weight: bold;
  margin-bottom: 7px;
  font-size: 1.4rem;
}

.cancel-dispatch-form-field-multiselect-container {
  height: 35vh;
  margin-top: 36px;
}

.cancel-dispatch-form-field-option {
  font-size: 1.4rem;
}

.cancel-dispatch-form-field-option span {
  margin-left: 5px;
}

.cancel-dispatch-form-field-text-input {
  margin-top: 10px;
  padding: 12px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 400;
}

.cancel-dispatch-add-file {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.cancel-dispatch-add-file input[type='file'] {
  padding: 12px;
  border: 1px solid #e3e3e3;
  outline: none;
  width: 80%;
  border-radius: 0px 5px 5px 0px;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 400;
}

.cancel-dispatch-add-file input[type='file']::file-selector-button {
  display: none;
}

.cancel-dispatch-add-file label {
  text-align: center;
  width: 20%;
  padding: 12px;
  font-size: 1.4rem;
  border-radius: 5px 0px 0px 5px;
  font-weight: 400;
  color: var(--main-color);
  background-color: var(--secondary-color);
}

.cancel-dispatch-add-file-label {
  padding: 12px;
  border: 1px solid #e3e3e3;
  outline: none;
  width: 80%;
  border-radius: 0px 5px 5px 0px;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 400;
}

.cancel-dispatch-add-file-label-placeholder {
  padding: 12px;
  border: 1px solid #e3e3e3;
  outline: none;
  width: 80%;
  border-radius: 0px 5px 5px 0px;
  box-sizing: border-box;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--placeholder-color);
}

.cancel-dispatch-add-file svg {
  vertical-align: unset;
}

.cancel-dispatch-field-error-msg {
  font-size: 1.2rem;
  color: red;
  font-weight: normal;
}

.cancel-dispatch-content-container {
  width: 100%;
}

.cancel-dispatch-search-filter {
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
}

.cancel-dispatch-filter-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1rem;
  height: 100%;
  padding: 10px 30px;
}

.cancel-dispatch-search-filter {
  display: flex;
  width: 100%;
  position: relative;
  height: 52px;
}

.cancel-dispatch-filter-options {
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  max-height: 200px;
  padding: 0;
  overflow: scroll;
  transition: all 0.1s;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.cancel-dispatch-filter-options-hidden {
  max-height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  padding: 0;
  transition: all 0.1s;
}

.cancel-dispatch-filter-options .cancel-dispatch-filter-option:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
  font-weight: var(--semibold);
  cursor: pointer;
}

.cancel-dispatch-filter-option label {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.cancel-dispatch-filter-option .radio {
  display: none;
}

.cancel-dispatch-filter-option {
  font-size: 1.2rem;
  padding: 10px 30px;
}

.cancel-dispatch-label-placeholder {
  color: var(--placeholder-color);
  font-size: 15px;
}

.cancel-dispatch-label-text {
  font-size: 15px;
}

.cancel-dispatch-title-text {
  font-size: 16px;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 16px;
}

.cancel-dispatch-father-container {
  margin: 0px;
}
