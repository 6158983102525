.printer-screen-wrapper {
  padding-top: var(--padding-top-bar);
  padding-left: var(--padding-left-bar);
}
.printer-header-wrapper {
  box-shadow: 0px 1px 4px #15223214;
  padding: 6px 20px;
  background-color: var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.printer-header-wrapper > h2 {
  color: var(--secondary-color);
  font-size: 1.8rem;
  letter-spacing: 0.36px;
}
.printer-header-buttons {
  display: flex;
  align-items: center;
  gap: 6px;
}
.printer-header-button {
  background-color: var(--button-color);
  color: var(--main-color);
  padding: 5px 10px;
  height: 40px;
  width: 170px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  outline: none;
}
.printer-header-button:hover {
  background-color: var(--secondary-color);
}
.printer-header-button:disabled {
  background-color: #f2f2f2;
  color: #afafaf;
}

.printer-stats-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  gap: 18px;
}
.printer-stats {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 10px 30px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
  color: var(--secondary-color);
}
.printer-stats .printer-stats-title {
  font-size: 24px;
  font-weight: 600;
}
.printer-stats .printer-stats-sub-title {
  font-size: 16px;
  margin-top: -5px;
}
.printer-stats > span {
  font-weight: 600;
  font-size: 55px;
}

.printer-options-wrapper {
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;
  gap: 6px;
  border-radius: 5px;
}
.printer-option {
  position: relative;
  flex: 1;
  height: 48px;
  background: white;
  border: 1px solid #afafaf;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.printer-option .printer-option-text {
  color: black;
}
.printer-option-input {
  position: relative;
  flex: 1;
  height: 48px;
  background: white;
  border: 1px solid #afafaf;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.printer-option-input .printer-option-input-text {
  color: black;
  padding: 10px 20px;
  font-size: 14px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
.printer-option:disabled,
.printer-option.disabled {
  background: #f2f2f2;
}
.printer-options {
  position: absolute;
  z-index: 99;
  top: 106%;
  left: 0;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
}
.printer-options-value {
  width: 100%;
  background-color: transparent;
  height: 40px;
  border: none;
  outline: none;
  text-align: start;
  padding-left: 20px;
}
.printer-options-value.selected,
.printer-options-value:hover {
  background-color: #f2f2f2;
}

.printer-table-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 345px);
}
.printer-table-wrapper-table {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  background: white;
  min-height: calc(100vh - 345px);
}
.printer-table-wrapper-table-body {
  height: fit-content;
}

.printer-table-loader-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 24px;
  gap: 20px;
  color: #123363;
}
.printer-table-loader-wrapper > p {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.printer-instructions-wrapper {
  border: 1px solid #123363;
  border-radius: 15px;
  padding: 30px 80px;
  /* margin-top: 150px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: var(--secondary-color);
}
.printer-table-loader-wrapper-button {
  color: var(--secondary-color);
  opacity: 0.8;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
  text-decoration: underline;
}
.printer-table-loader-wrapper-button:hover {
  opacity: 1;
}
.printer-instructions-wrapper .printer-instructions-header {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.printer-instructions-wrapper .printer-instructions-header-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.printer-instructions-wrapper .printer-instructions-header-item-image {
  width: 14px;
  height: 14px;
}
.printer-instructions-header .printer-instructions-header-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 4px;
}
.printer-instructions-wrapper .printer-instructions-header-item-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 4px;
  width: 28ch;
}
.printer-instructions-wrapper .printer-instructions-header-item-sub-title {
  font-size: 16px;
  font-weight: 600;
}
.printer-instructions-wrapper .printer-instructions-header-item {
  display: flex;
  align-items: center;
  gap: 6px;
}
.printer-instructions-wrapper .printer-instructions-footer-wrapper {
  text-align: center;
}
.printer-instructions-wrapper .printer-instructions-footer-tex {
  font-size: 16px;
}

/* Department Filter */
.department-filters-popover-wrapper {
  position: relative;
  font-size: 14px;
  background: white;
  padding: 14px 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 260px;
  min-height: 260px;
  min-width: 276px;
  overflow: auto;
  padding-bottom: 77px;
}
.department-filters-popover-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 173px;
}
.department-filters-popover-options {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.department-filters-popover-text-option {
  color: var(--secondary-color);
  margin-top: -6px;
}
.department-filters-popover-buttons {
  display: flex;
  gap: 4px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px;
  background: white;
}
.department-filters-popover-button-submit,
.department-filters-popover-button-clean {
  border: 1px solid var(--secondary-color);
  border-color: var(--secondary-color);
  padding: 11px 0;
  border-radius: 5px;
  color: var(--secondary-color);
  width: 120px;
  align-items: center;
  background-color: #ffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.department-filters-popover-button-submit {
  background-color: var(--secondary-color);
  color: #ffff;
}
