.metodo-entrega-left {
  min-width: 1188px;
  height: calc(100vh - 84px);
}

.metodo-entrega-main {
  background-color: var(--main-color);
  border-radius: 5px;
  box-shadow: 0px 1px 4px #15223214;
  padding-bottom: 10px;
  min-height: 100%;
  margin-bottom: 10px;
}

.metodo-entrega-header {
  padding-top: 40px;
  padding-left: 50px;
}

.metodo-entrega-header h2 {
  color: var(--secondary-color);
  font-size: 16px;
  font-weight: var(--semibold);
}

.metodo-entrega-buttons {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 9px;
  padding: 0 121px;
}

.metodo-entrega-buttons button {
  padding: 13px 0;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.metodo-entrega-selected {
  background-color: var(--secondary-color);
  color: var(--main-color);
}
.button-not-selected {
  background-color: var(--main-color);
  color: var(--inactive-color);
  border: 1px solid var(--inactive-color);
}

.img-domicilio-white {
  filter: invert(7%) sepia(0%) saturate(5179%) hue-rotate(55deg) brightness(75%)
    contrast(116%);
}

.content-domicilio {
  padding: 0 121px;
  margin-top: 41px;
}

.content-domicilio h2 {
  font-weight: var(--semibold);
  font-size: 0.83vw;
  letter-spacing: -0.16px;
  line-height: 17px;
}

.metodo-entrega-button-save {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.entrega-buttons-save {
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  opacity: 1;
  color: var(--main-color);
  width: 245px;
  max-width: 245px;
  max-height: 44px;
  height: 44px;
  padding: 13px 48px;
  font-size: 14px;
}
.button-save-edition-type {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0;
  gap: 10px;
}
.edition-button-save,
.edition-button-close {
  background: var(--secondary-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  opacity: 1;
  color: var(--main-color);
  width: 245px;
  max-width: 245px;
  max-height: 44px;
  height: 44px;
  padding: 13px 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edition-button-save {
  background: white;
  color: var(--secondary-color);
}
.address-edition-header {
  width: 100%;
  background: white;
  height: 100px;
  padding: 0 121px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 23px;
}
.address-edition-header > img {
  cursor: pointer;
}

.address-options {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 9.4px;
  margin-top: 19px;
  height: 100%;
}

.address-options .domicilio-option {
  background-color: #f3f3f3;
  border-radius: 5px;
  display: flex;
  gap: 20px;
  padding: 11px 16px;
  align-items: center;
  justify-content: space-between;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
}
/* .address-options .domicilio-option:hover {
  overflow: visible;
} */

.name-domicilio {
  display: flex;
  gap: 11px;
  width: 70%;
}

.domicilio-option .fa-check {
  display: none;
}

.domicilio-option input {
  height: 18px;
  width: 18px;
}

.add-domicilio {
  min-width: 20px;
  min-height: 20px;
  background-color: var(--placeholder-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.3rem;
  color: #f3f3f3;
}
.domicilio-option.selected input {
  opacity: 0;
  display: none;
  cursor: pointer;
  height: 0;
  width: 0;
}

.domicilio-option.selected .fa-check {
  display: block;
  border-radius: 50%;
  padding: 3px;
  width: 12px;
  height: 12px;
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.address-options .domicilio-option.selected {
  border: 2px dotted var(--secondary-color);
  background-color: var(--main-color);
}

.address-options .domicilio-option.selected .name-domicilio > label {
  color: var(--secondary-color);
}

.address-options li label {
  color: var(--placeholder-color);
  font-size: 0.75vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.address-options li > input {
  cursor: pointer;
}

.content-sucursal {
  margin-top: 41px;
  padding: 0 121px;
}

.content-sucursal h2 {
  font-size: 14px;
  font-weight: var(--semibold);
}

.sucursales-options {
  position: relative;
  display: block;
  margin-top: 1.5em;
  padding: 0;
}

.surcural-options-address {
  padding: 0;
  list-style: none;
  width: 100%;
  margin-top: 20px;
}
.surcural-options-address .sucursal-address {
  background-color: #f3f3f3;
  border-radius: 5px;
  display: flex;
  padding: 11px 38px;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}

.sucursal-address .fa-check {
  display: none;
}

.sucursal-address input {
  height: 20px;
  width: 20px;
}

.sucursal-address.selected input {
  opacity: 0;
  display: none;
  cursor: pointer;
  height: 0;
  width: 0;
}

.sucursal-address.selected .fa-check {
  display: block;
  border-radius: 50%;
  padding: 3px;
  width: 12px;
  height: 12px;
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.surcural-options-address .sucursal-address.selected {
  border: 2px dotted var(--secondary-color);
  background-color: var(--main-color);
}

.surcural-options-address .sucursal-address.selected > label {
  color: var(--secondary-color);
}

.surcural-options-address li label {
  color: #000000;
  font-size: 0.83vw;
}
.surcural-options-address li p {
  color: #646464;
  font-size: 0.72vw;
}

.content-form-direccion {
  margin-top: 30px;
  padding: 0 121px;
}

.content-form-direccion .comment {
  font-size: 1.6rem;
  margin-bottom: 23px;
}

.content-form-direccion input {
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  padding: 6px 19px;
  font-size: 1.23rem;
}

.mapa-content {
  border-radius: 1px;
  padding-top: 0;
  padding-left: 10%;
  padding-right: 10%;
  position: static;
  vertical-align: middle;
  display: flex;
  width: 100%;
  background-color: #919191;
}

.form-group-select {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 0;
}

.form-group-select select {
  display: block;
  width: 100%;
  background: var(--main-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  opacity: 1;
  padding: 6px 19px;
  font-size: 1.5rem;
}

.group-inputs-casa-phone {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 13px;
}

.group-inputs-casa-phone input {
  padding: 10px 25px;
  font-size: 14px;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
}

.edificio-input input {
  padding: 10px 25px;
  width: 100%;
  font-size: 14px;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
}

.add-address-perfil {
  display: flex;
}

.domicilio-continue {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.domicilio-continue button {
  font-size: 14px;
  color: var(--main-color);
  padding: 13px 90px;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
}

.sucursales-options .select-wrapper select {
  align-items: center;
  width: 100%;
  margin: 0;
  background: none;
  border: 1px solid var(--inactive-color);
  border-radius: 6px;
  opacity: 1;
  font-size: 1.38em;
  color: #444;
  padding: 0.6em 1.9em 0.5em 2.8em;
  line-height: 1.3;
  -webkit-appearance: none;
  appearance: none;
  z-index: 10;
  position: relative;
  background: transparent;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  outline: none;
  z-index: 0;
  content: '\2304';
  font-size: 2rem;
  top: -0.1em;
  right: 2em;
  font-weight: lighter;
  color: var(--secondary-color);
  position: absolute;
}

.delivery-cliente-contado {
  padding: 0 121px;
  margin-top: 20px;
}

.delivery-cliente-contado p {
  /* font-size: 0.6vw; */
  background-color: rgba(203, 115, 95, 0.3);
  padding: 10px;
  border-radius: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .metodo-entrega-buttons button {
    padding: 9px 0;
    font-size: 11px;
    border-radius: 5px;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
  }
  .entrega-buttons-save {
    width: 278px;
    max-width: 278px;
  }
}
