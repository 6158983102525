body {
  --table-line: rgba(204, 204, 204, 0.3);
}

.buscar-cliente-content {
  padding: 0 20px;
  padding-top: var(--padding-top-bar);
}

.buscar-cliente-main {
  padding: 21px 22px;
}

.search-bar-client {
  width: 100%;
  display: flex;
  gap: 6.8px;
  margin-bottom: 22px 10px 22px 0;
  align-items: center;
  height: 45px;
}

.search-bar-client input {
  padding: 12px 23px;
  width: 100%;
  border: 0.5px solid var(--inactive-color);
  border-radius: 4px;
  outline: none;
  color: #7e7e7e;
  font-size: 1.8rem;
  letter-spacing: 0.36px;
}

.search-bar-client button {
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  letter-spacing: 0.34px;
  background-color: var(--secondary-color);
  padding: 16px 28px;
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--main-color);
}

.search-bar-client button img {
  margin-right: 6.8px;
}

.search-bar-client-input {
  position: relative;
  display: flex;
  width: 70%;
}

.buscar-cliente-search-icon {
  position: absolute;
  top: 35%;
  right: 1.8%;
}

.tabla-clientes {
  padding: 28px 43px;
}

.table-header-clientes {
  padding-bottom: 20px;
  color: #7e7e7e;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
}

.b-bttm {
  border-bottom: 1px solid var(--table-line);
}

.cliente-contado-backdrop,
.clienteContadoModal {
  z-index: 1100;
}

.clienteContadoModal .modal-content {
  padding: 30px;
}

.clienteContadoModal .modal-content h2 {
  font-size: 20px;
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.clienteContadoModal .modal-content input {
  font-size: 15px;
  padding: 5px;
}

.clienteContadoModal .modal-content button {
  margin-top: 10px;
  font-size: 15px;
  border: none;
  width: 20%;
  background-color: var(--secondary-color);
  color: white;
  align-self: flex-end;
}

.img-close-modal {
  width: 12px;
  height: 14px;
  margin: 0px 10px;
  cursor: pointer;
}
