body {
  background-color: #f8f9fd;
}

/* .departments{
    padding: calc(var(--padding-top-bar) + 7.5px) 10px 12px calc(var(--padding-left-bar) + 12px); 
} */

.department-cards {
  padding: 22px 25px 75px 25px;
  background-color: var(--main-color);
  box-shadow: 0px 1px 4px #15223214;
  min-width: 1180px;
  border-radius: 5px;
}

.department-search {
  position: relative;
  margin-bottom: 10px;
}

.department-search input {
  width: 100%;
  box-sizing: border-box;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  padding: 12px 18px;
  font-size: 18px;
  letter-spacing: 0.36px;
}

.department-search img {
  position: absolute;
  top: 17px;
  right: 20px;
  width: 15px;
  height: 15px;
}

.card {
  min-width: 16%;
  min-height: 173px;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.card-text {
  margin-top: 20px;
  text-align: center;
}

.card-text h3 {
  font-size: 0.7vw;
}
.card:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.card:hover .filter-white {
  filter: invert(98%) sepia(91%) saturate(2%) hue-rotate(254deg)
    brightness(107%) contrast(100%);
}

.filter-white {
  width: inherit;
  height: inherit;
}

.card-image {
  width: 90%;
  height: 120px;
}

@media (max-width: 1725px) {
  .card-image {
    width: 90%;
    height: 90px;
  }
}
