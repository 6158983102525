.close-button {
  display: flex;
  justify-content: flex-end;
}

.close-button .fa-xmark {
  font-size: 3rem;
}

.product-detail-container-padding {
  padding: 0 38px;
  margin-bottom: 10px;
}

.container-inventary-product {
  display: flex;
  gap: 10px;
}

.product-detail-box {
  justify-content: center;
  display: flex;
  width: 64%;
  height: 100%;
  background-color: #fff;
  margin: 70px 83px;
  padding-top: 32px;
  outline: none;
  overflow: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  padding-bottom: 32px;
}

.inventory-width {
  width: 100%;
}

@media (max-width: 1366px) {
  .product-detail-box {
    width: 62.8%;
  }
}

@media (max-width: 1200px) {
  .product-detail-box {
    width: 54%;
  }
}

@media (max-width: 991px) {
  .product-detail-box {
    width: 100%;
  }
}

.product-card-image {
  padding: 10px 10px;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card-image img {
  max-width: 90%;
  max-height: 90%;
}

.product-card-buttons button:nth-child(1):hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.product-card-buttons button:nth-child(2):hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.product-card-discount {
  text-decoration: line-through;
  color: #848383;
  font-size: 0.69vw;
}

.product-card-discount-container {
  margin-bottom: 8px;
}

.product-promo {
  position: absolute;
  top: 0;
  left: 11px;
  padding: 6px 22px;
  background-color: #ed1c24;
  border-radius: 0 0 5px 5px;
  font-size: 0.81vw;
  font-weight: var(--semibold);
  color: var(--main-color);
}

.product-card {
  position: relative;
  box-shadow: 0px 0px 3px #82828229;
  border: 1px solid #dbdbdb;
  height: 100%;
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-card2 {
  /* position: relative; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-card-generals {
  text-align: left;
}
.product-card-generals-title {
  font-size: 0.92vw;
  font-weight: 400;
  margin-bottom: 1px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  height: 3em;
  max-height: 3em;
  min-height: 3em;
}
.product-card-brand {
  font-size: 0.92vw;
}

.product-card-sku {
  font-size: 0.69vw;
}

.product-card-store {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.8vw;
  margin-top: 1px;
}

.product-card-price {
  display: flex;
  gap: 6px;
}

.product-card-price p {
  font-size: 1.04vw;
  font-weight: 600;
}

.product-no-price {
  color: red;
  font-size: 0.5vw;
  text-align: center;
}

.product-card-buttons {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}
.product-card-buttons button {
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  border: none;
  font-size: 0.7vw;
  background-color: transparent;
  padding: 10px 4px;
  border-radius: 5px;
}

.background-image {
  background-image: url('../../Assets/Images/Product/cotizar_cielo_raso_x2.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 90%;
  height: 100%;
  margin-left: 10px;
  cursor: pointer;
}


.modal-clase-producto {
  position: absolute;
  margin: 0;
  /* padding-top: 70px; */
  padding-bottom: 30px;
  max-height: calc(100vh - var(--padding-top-bar));
}

.modal-clase-producto-cotizacion {
  /* position: absolute; */
  margin: 0;
  padding-top: 70px;
  padding-bottom: 30px;
  height: 100%;
}

.modal-clase-producto-cotizacion .modal-dialog {
  margin: 0;
  margin-left: 62px;
  max-width: 100%;
  height: 100%;
}

.modal-clase-producto-cotizacion .modal-content {
  padding-top: 30px;
  border: none;
  border-radius: 0 !important;
  height: 100%;
}

.modal-clase-producto .modal-dialog {
  margin: 0;
  min-width: 100%;
  height: 100%;
}

.modal-clase-producto .modal-content {
  padding-top: 30px;
  border: none;
  border-radius: 0 !important;
  height: 100%;
}

.product-cards-wrapper {
  display: grid;
  grid-gap: 10px 7px;
  grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
}
@media (min-width: 1500px) {
  .product-cards-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  }
  .product-card-image {
    height: 180px;
  }
  
}
@media (min-width: 2000px) {
  .product-cards-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  .product-card-image {
    height: 250px;
  }
}

