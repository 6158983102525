.product-detail-modal-content {
  position: relative;
}

.product-detail-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
}

.general-product-data {
  margin-top: 8px;
}

.container-card-product-detail {
  width: 100%;
  background-color: #fff;
}

.general-product-data .product-title {
  font-size: 2.7rem;
  letter-spacing: 0.27px;
  line-height: 37px;
  color: #1a1a1a;
}

.general-product-data .product-brand {
  font-size: 2.7rem;
  letter-spacing: 0.27px;
  color: #1a1a1a;
  margin-top: 5px;
  font-weight: var(--regular);
}

.general-product-data .product-sku {
  margin-top: 12px;
  font-size: 1.8rem;
}

.product-short-description {
  font-size: 1.6rem;
}

.product-tag {
  font-size: 13px !important;
  line-height: 24px;
}

.product-tags {
  margin-top: 9px;
}

.general-product-data .product-stars {
  display: flex;
  gap: 7px;
  align-items: center;
}

.general-product-data .star-count {
  font-size: 1.4rem;
  color: #919191;
}

/* .general-product-data {
    margin-top: 14px;
    font-size: 3.5rem;
    font-weight: var(--semibold);
} */
.product-price {
  font-size: 35px !important;
  font-weight: var(--semibold);
}

.cmf-content {
  display: flex;
  gap: 10.5px;
  align-items: center;
  font-size: 1.5rem;
  line-height: 15px;
}

.cmf-content button {
  border: none;
  background-color: transparent;
  color: var(--secondary-color);
  text-decoration: underline;
  padding: 0;
}

.product-counter {
  display: flex;
  align-items: center;
  margin-top: 13px;
  gap: 5px;
}

.product-counter p {
  font-size: 2rem;
}

.product-btn-counter {
  border: 1px solid #f8f9fd;
  background-color: var(--main-color);
  border-radius: 4px;
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);
}

.plus {
  font-size: 4rem;
}

.minus {
  font-size: 7rem;
  padding-bottom: 10px;
}

.product-unidades {
  color: var(--secondary-color);
  font-size: 1.4rem;
  letter-spacing: 0.28px;
  display: flex;
  gap: 16px;
}

.product-margin {
  margin: 10px 0;
  color: var(--secondary-color);
  font-size: 1.4rem;
}

.product-warranty {
  margin-top: 19px;
  padding: 15px 22px;
  border: 1px solid #dee2e6;
}

.product-warranty-header {
  display: flex;
  gap: 9px;
  align-items: center;
}

.product-warranty-header h2 {
  font-size: 1.5rem;
  color: var(--secondary-color);
  font-weight: var(--semibold);
}

.product-warranty-text {
  margin-top: 7.3px;
}

.product-warranty-text p {
  font-size: 1.1rem;
}

.product-warranty-options {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 9.4px;
  width: 96%;
  margin-top: 7.8px;
}

.product-warranty-options .product-warranty-option {
  background-color: #f3f3f3;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 5px 16px;
  align-items: center;
}

.product-warranty-option .fa-check {
  display: none;
}

.product-warranty-option.selected input {
  opacity: 0;
  display: none;
  cursor: pointer;
  height: 0;
  width: 0;
}

.product-warranty-option.selected .fa-check {
  display: block;
  border-radius: 50%;
  padding: 2.5px 3px;
  width: 8px;
  height: 8px;
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.product-warranty-options .product-warranty-option.selected {
  border: 2px dotted var(--secondary-color);
  background-color: var(--main-color);
}

.product-warranty-options .product-warranty-option.selected > label {
  color: var(--secondary-color);
}

.product-warranty-options li label {
  color: #919191;
  font-size: 0.57vw;
}

.product-detail-buttons {
  margin-top: 14px;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 9px;
  width: 98.3%;
}

.product-detail-buttons button {
  padding: 6px 4px;
  font-size: 1.4rem;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  text-align: center;
}

.product-detail-button-agregar {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.product-detail-button-inventario {
  background-color: var(--main-color);
  color: var(--secondary-color);
}

.product-detail-accordion {
  margin-top: 44px;
}

.detalles-accordion-container,
.ficha-accordion-container {
  border: 1px solid #dbdbdb;
  padding: none;
  padding: 44px 18px;
}

.product-detail-accordion {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.recommended-products {
  background-color: #f2f6fb;
  margin-top: 28px;
  padding: 30px 30px;
}

.recommended-products h2 {
  font-weight: var(--semibold);
  font-size: 1.4rem;
}

.similar-products {
  margin-top: 28px;
  padding: 45px 75px;
}

.similar-products h2 {
  font-weight: var(--semibold);
  font-size: 1.4rem;
}

.products-carrousel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-top: 34px;
  margin-bottom: 40px;
}

/* .products-carrousel-content{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 18px;
} */

.products-carrousel-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 34px;
}

.products-carrousel .fa-chevron-left,
.products-carrousel .fa-chevron-right {
  font-size: 3rem;
}

.carousel {
  /* width: 80%; */
  margin: 0 auto;
  margin-right: 30px;
  /* padding-top: 30px; */
  padding-left: 30px;
  padding-bottom: 10px;
}

.carousel .control-dots .dot {
  background-color: gray;
  width: 10px;
  height: 10px;
  box-shadow: none;
}

.carousel .control-dots .dot.selected {
  background-color: #ffde20;
  width: 10px;
  height: 10px;
  box-shadow: none;
}

.show-inventory-tabs {
  width: 100%;
  padding: 0 100px 80px 100px;
  background-color: var(--main-color);
}

.closing-inventory-image {
  display: block;
  position: absolute;
  top: 20px;
  right: 90px;
}

.react-tabs__tab-list {
  border: none;
}

.react-tabs__tab {
  font-size: 1.8rem;
  letter-spacing: 0.18px;
  padding: 10px 38px;
  color: var(--inactive-color);
}

.react-tabs__tab.react-tabs__tab--selected {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.inventory-location {
  display: flex;
  box-shadow: 0px 0px 3px #0000001c;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  margin-bottom: 15px;
}

.inventory-location-generals {
  flex-basis: 70%;
  display: grid;
  grid-template-columns: 40% 10% 30%;
  gap: 78px;
}

.inventory-location-generals h2 {
  font-weight: var(--semibold);
  font-size: 0.83vw;
  letter-spacing: -0.16px;
  line-height: 17px;
}

.react-tabs__tab-panel {
  padding: 0 10px;
}

.inventory-location-quantity p {
  font-size: 1.8rem;
  line-height: 24px;
  letter-spacing: -0.18;
  background-color: #11d29e;
  padding: 6px 12.5px;
  border-radius: 6px;
}

.frecuently-bought-products {
  display: flex;
  align-items: center;
  gap: 35px;
  margin-top: 35px;
}

.frecuently-bought-products .fa-plus {
  font-size: 2rem;
}

.frequently-card {
  max-width: 150px;
  height: 150px;
}

.frequently-card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.frecuently-bought {
  margin-top: 40px;
}

.frecuently-bought .frecuently-bought-title {
  font-size: 1.4rem;
  font-weight: var(--semibold);
}

.frecuently-bought-add {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.frecuently-bought-add button {
  margin-top: 23px;
  border: none;
  font-size: 1.1rem;
  font-weight: var(--semibold);
  padding: 8px 48px;
  background-color: var(--secondary-color);
  color: var(--main-color);
  border-radius: 5px;
}

.frecuently-bought-add h2:nth-child(1) {
  font-size: 1.9rem;
  font-weight: var(--semibold);
}

.frecuently-bought-add h2:nth-child(2) {
  font-size: 2.2rem;
  font-weight: var(--semibold);
}

.frecuently-bought-products-options {
  margin-top: 48px;
}

.frecuently-bought-products-options div {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 7px;
}

.frecuently-bought-products-options div label {
  font-size: 0.9rem;
  font-weight: var(--semibold);
}

.cmf-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 408px;
  min-height: 180px;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
  padding: 30px 33px;
}

.top-cmf {
  display: flex;
  justify-content: space-between;
  margin-bottom: 43px;
}

.top-cmf img:nth-child(1) {
  width: 20%;
}

.cmf-clipboard {
  display: flex;
  gap: 20px;
  align-items: center;
}

.cmf-clipboard svg {
  font-size: 2.5rem;
}

.cmf-clipboard p {
  font-size: 1.4rem;
  font-weight: var(--semibold);
}

.modal-box-specific {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -46%);
  width: 1200px;
  max-height: 1013px;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.modal-specific-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.modal-specific-header h2 {
  font-size: 1.6rem;
  letter-spacing: 0.16px;
  color: var(--secondary-color);
}

.modal-specific-container {
  padding: 40px 70px;
  background-color: var(--main-color);
  height: 100%;
}

.modal-specific-body {
  padding-bottom: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal-specific-quantities {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 34px;
  box-shadow: 0px 0px 3px #0000001c;
  border-radius: 5px;
  margin-bottom: 15px;
}

.modal-specific-quantities p:nth-child(1) {
  font-size: 1.6rem;
  letter-spacing: -0.16px;
}

.modal-specific-quantities p:nth-child(2) {
  font-size: 1.8rem;
  letter-spacing: -0.18px;
  padding: 4px 12px;
  border-radius: 5px;
}

.inventory-location-generals button {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-size: 0.72vw;
  color: var(--secondary-color);
}

.calculadora-de-piso {
  border: 1px solid #dee2e6;
  padding: 24px 22px;
  width: 97%;
  margin-top: 20px;
}

.calculadora-de-piso-header {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 12px;
}

.calculadora-de-piso-header h2 {
  font-size: 1.8rem;
  font-weight: var(--semibold);
  color: var(--secondary-color);
}

.calculadora-de-piso-body .instrucciones-calc {
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.calculadora-de-piso-body form input {
  font-size: 1.4rem;
  border: 1px solid #919191;
  padding: 8px 18px;
  width: 60%;
  outline: none;
}

.calculadora-de-piso-body form button {
  font-size: 1.4rem;
  font-weight: var(--semibold);
  padding: 8px 30px;
  color: var(--secondary-color);
  border: 1px solid #0055b8;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--main-color);
}

.calculadora-de-piso-resultado {
  margin-top: 12px;
  text-align: center;
  background-color: #d9e5f5;
  color: var(--secondary-color);
  padding: 8px 0;
  width: 85%;
  font-size: 1.4rem;
}

.especial-price-caja {
  font-size: 3.5rem;
  font-weight: var(--semibold);
}

.product-detail-title {
  color: red;
}

.return-button {
  display: flex;
  flex-direction: start;
}

.return-button:hover {
  cursor: pointer;
}

.ficha-tecnica-info {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 10px;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f2f6fb;
}

.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #e2ecf7;
}

.table-striped > thead > tr > th {
  background-color: #e2ecf7;
}

.table-striped > thead > tr > th:nth-child(2) {
  background-color: #f2f6fb;
}

.table-striped > tbody > tr:nth-child(odd) > td:nth-child(2),
.table-striped > tbody > tr:nth-child(even) > td:nth-child(2),
.table-striped > tbody > tr:nth-child(odd) > th:nth-child(2),
.table-striped > tbody > tr:nth-child(even) > th:nth-child(2) {
  background-color: #f2f6fb;
}

.table-striped > tbody > tr:nth-child(odd) > td:nth-child(2),
.table-striped > tbody > tr:nth-child(even) > th:nth-child(2) {
  background-color: #ffffff;
}

.table-striped {
  border: 1px solid #e2ecf7;
}

.table > :not(caption) > * > * {
  box-shadow: none;
}

.table > :not(:first-child) {
  border-top: none;
}

.general-table-header {
  font-size: 16px;
  letter-spacing: 0.32px;
}

.general-table-header.codes-external {
  width: 50%;
}

.copiado-confirmation {
  background-color: rgba(211, 211, 211, 0.264);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  color: var(--secondary-color);
}

.col-asunto-text {
  background-color: #f2f6fb;
  padding: 22px 18px;
  height: 315px;
}

.valoraciones-asunto,
.valoraciones-comentarios {
  width: 100%;
  border: none;
  padding: 8px 18px;
  font-size: 0.54vw;
  margin-bottom: 10px;
}

.valoraciones-comentarios {
  max-height: 186px;
  height: 186px;
  resize: none;
}

.button-valoracion-right {
  padding: 6px 28px;
  background-color: var(--secondary-color);
  font-size: 0.54vw;
  color: var(--main-color);
  border: none;
  border-radius: 5px;
}

.button-valoracion {
  display: flex;
  justify-content: flex-end;
}

.col-valoraciones-puntos {
  padding: 0 40px;
}

.availablity-header {
  font-size: 0.7vw;
  letter-spacing: 0.36px;
  margin: 30px 0;
  color: var(--secondary-color);
}

.carousel .slide {
  background-color: #fff;
}

.inventory-modal-content {
  padding: calc(var(--padding-top-bar) + 48px) 52px 48px
    calc(var(--padding-left-bar) + 52px);
}

.inventory-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.inventory-header h2 {
  color: var(--secondary-color);
}

.inventory-search input {
  width: 100%;
  height: 100%;
  outline: none;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  padding: 9px 18px;
  font-size: 16px;
}

.modal-fullscreen .modal-content {
  min-height: 100vh;
  height: auto;
}

.container-option-materials-calculator {
  display: flex;
  align-items: center;
  padding: 8px 0px;
  background-color: #0030821A;
}
.container-option-materials-calculator img:first-of-type {
  margin: 0px 10px 0px 20px;
  width: 24px;
  height: 28px;
}
.container-option-materials-calculator img:nth-of-type(2) {
  margin-right: 20px;
  margin-left: 20px;
}

.arrow-right {
  width: 20px;
  transform: rotate(270deg);
  color: #003082;
  opacity: 1;
  cursor: pointer;
}

.container-option-materials-calculator p:first-of-type {
  font-weight: 600 !important;
  text-align: left;
  font: normal normal normal 14px/17px Open Sans;
  letter-spacing: 0.14px;
  color: #022767;
}
.container-option-materials-calculator p:nth-of-type(2) {
  font-weight: 200;
  text-align: left;
  font: normal normal normal 12px/15px Open Sans;
  letter-spacing: 0.12px;
  color: #022767;
}

.container-option-materials-calculator span {

  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
}
