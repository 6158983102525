.table-header.columna-6 {
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 100px;
}

.table-cotizacion-product.columna-6 {
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 100px;
}

.money-data.columna-6-special {
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 100px;
}

.cotizacion-editar-header {
  display: flex;
  justify-content: space-between;
}

.cotizacion-share-buttons {
  display: flex;
  gap: 6px;
  font-size: 0.72vw;
}
.restore-container {
  padding: 45px 17.5px 42px 17px;
}
.modal-box input {
  margin-top: 20px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 17px;
  font-weight: 400;
}
.modal-box .input-price {
  margin-top: 10px;
}
.modal-box .modal-buttons {
  margin-top: 15px;
  display: flex;
  padding: 0 9px;
  justify-content: space-around;
}
.cotizacion-share-buttons button {
  border: none;
  padding: 14px 77px;
  border-radius: 5px;
  color: var(--main-color);
  display: flex;
  align-items: center;
  gap: 8px;
}

.cotizacion-share-buttons button:nth-child(1) {
  background-color: #11d29e;
  padding: 14px 77px;
}

.cotizacion-share-buttons button:nth-child(2) {
  background-color: #12c1f8;
  padding: 13px 52px;
}

.cotizacion-share-buttons button svg {
  font-size: 2.3rem;
}

.info-general-cliente .fa-square-pen,
.info-general-retiro .fa-square-pen,
.info-general-vendedor .fa-square-pen {
  position: absolute;
  font-size: 1.8rem;
  top: 21px;
  right: 27px;
}

.info-general-vendedor .status {
  display: inline-block;
  background-color: #11d29e;
  padding: 4px 8px;
  margin-top: 9px;
  border-radius: 5px;
}

.info-general-vendedor .status-pending {
  display: inline-block;
  background-color: rgb(248, 151, 161);
  padding: 4px 8px;
  margin-top: 9px;
  border-radius: 5px;
}

.discount-text {
  text-align: left;
  font-size: 1.4rem;
  letter-spacing: 0.59px;
  color: #ff2a40;
  margin-top: 11px;
}

.discount-text-amount {
  text-align: center;
  font-size: 1.4rem;
  letter-spacing: 0.59px;
  color: #ff2a40;
  margin-top: 11px;
}

.table-cotizacion-product {
  position: relative;
}

.table-cotizacion-product .product-action-buttons {
  font-size: 1rem;
  display: flex;
  gap: 22px;
  justify-content: left;
}

.product-action-buttons .fa-square-pen {
  margin: 0;
}

.cotizacion-share-buttons a {
  text-decoration: none;
}

.product-data .product-name {
  text-align: left;
  font-size: 16px;
}

.button-loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.restore-container .buscar-cliente-content {
  padding: 0;
}

.edit-contado {
  width: 90%;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
}

.cliente-contado-edit {
  position: relative;
}

.edit-contado-buttons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2.5rem;
}

.delivery-info-container {
  margin-top: 45px;
}

.delivery-info-container .metodo-entrega-left {
  height: calc(100vh - 65px);
}

.delivery-info-container .metodo-entrega-main {
  padding-left: 50px;
}

#price_change_auth {
  color: var(--main-color);
  background-color: #ff2a40;
}

.top-action-table-buttons {
  display: flex;
  gap: 13px;
}

.top-action-table-buttons button {
  border: none;
  font-size: 18px;
  padding: 6px 12px;
  border-radius: 5px;
}

.cotizacion-labels {
  display: flex;
  gap: 10px;
}

.money-data-totals p {
  font-size: 16px;
}

.money-data-totals p:last-child {
  font-size: 18px;
}

.money-data-text p {
  font-size: 16px;
}

.money-data-text p:last-child {
  font-size: 18px;
}

.margin-total p {
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .money-data-text p {
    font-size: 14px;
  }
  .money-data-text p:last-child {
    font-size: 16px;
  }
  .money-data-totals p {
    font-size: 14px;
  }

  .money-data-totals p:last-child {
    font-size: 16px;
  }
  .margin-total p {
    font-size: 14px;
  }
}

.cotizacion-alert {
  color: #003082;
}

.cotizacion-alerta {
  color: #089a03;
}

.guardar_button_disabled {
  padding: 13px 95px;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  background-color: var(--inactive-color);
  color: var(--secondary-color);
  font-size: 1.4rem;
}
