.cotizacion-generada-container {
  padding: calc(var(--padding-top-bar) + 8px) 12px 12px
    calc(var(--padding-left-bar) + 12px);
}

.cotizacion-generada {
  background-color: var(--main-color);
  border-radius: 5px;
  padding: 49px 53px;
  box-shadow: 0px 1px 4px #15223214;
  overflow: hidden;
}

.cotizacion-generada .titulo-cotizacion {
  font-size: 21px;
  letter-spacing: 0.42px;
}

.info-general {
  margin-top: 17px;
  display: grid;
  gap: 19px;
  grid-template-columns: 27% 27% 27% 20%; /*repeat(3, 1fr); */
  margin-bottom: 20px;
}

.info-general-cliente,
.info-general-retiro,
.info-general-vendedor {
  padding: 21px 39px;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 5px;
  font-size: 1.4rem;
  position: relative;
}

.info-general-codigo-qr img {
  width: 50%;
  display: block;
  margin: 0 auto;
}

.cotizacion-generada-nota {
  margin-top: 9px;
  padding: 24px 53px;
  background-color: #d6ecfa;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 5px;
}

.cotizacion-generada-nota h2 {
  font-size: 1.6rem;
  letter-spacing: 0.32px;
  margin-bottom: 12px;
  color: #3f4254;
  font-weight: var(--semibold);
}

.cotizacion-generada-nota p {
  font-size: 1.6rem;
  letter-spacing: 0.32px;
}

.cotizacion-generada-documento {
  background-color: var(--main-color);
  margin-top: 9px;
  padding: 46px 57px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 5px;
}

.cotizacion-generada-documento .adjuntar {
  display: flex;
  align-items: center;
  gap: 77px;
}

.cotizacion-generada-documento input[type='file'] {
  font-size: 1.4rem;
  color: #3f4254;
  letter-spacing: 0.28px;
}

.cotizacion-generada-documento h2 {
  color: #3f4254;
  margin-bottom: 20px;
  font-size: 1.5rem;
  letter-spacing: 0.32px;
}

.cotizacion-generada-documento textarea {
  width: 90%;
  height: 130px;
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  resize: none;
  font-size: 15px;
}

.cotizacion-generada-documento label {
  padding: 14px 58px;
  font-size: 1.7rem;
  border-radius: 5px;
  color: var(--main-color);
  background-color: var(--secondary-color);
}

.cotizacion-generada-documento input[type='file']::file-selector-button {
  display: none;
}

.cotizacion-save-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cotizacion-save-button .guardar_button {
  padding: 13px 95px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  background-color: var(--secondary-color);
  color: var(--main-color);
  font-size: 1.4rem;
}

.cotizacion-general-productos {
  margin-top: 56px;
}

.cotizacion-general-productos .table-header {
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
}

.cotizacion-general-productos .table-header h3 {
  font-size: 1.4rem;
  letter-spacing: 0.59px;
  color: var(--secondary-color);
  font-weight: var(--semibold);
}

.table-cotizacion-product {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
  align-items: center;
  text-align: center;
  font-size: 1.6rem;
  padding: 21px 0;
}

.table-cotizacion-product-general-information {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  align-items: center;
}

.table-cotizacion-product-general-information img {
  width: 10%;
}

.money-data {
  text-align: center;
  display: grid;
  margin-top: 22px;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
}

.money-data-text {
  text-align: left;
}

.money-data-totals {
  text-align: left;
  /* margin-right: 40%; */
}

.money-data-text p,
.money-data-totals p {
  margin-bottom: 9px;
  font-size: 1.6rem;
}

.money-data-text p:nth-child(4),
.money-data-totals p:nth-child(4) {
  font-size: 1.6rem;
  font-weight: var(--semibold);
}

.table-cotizacion-product:nth-child(even) {
  background-color: #fbfbfb;
}

.modal-box-channel,
.modal-box-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 448px;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
  padding: 25px 32px;
  min-height: 276px;
}

.closing-button {
  display: flex;
  justify-content: flex-end;
}

.closing-button.modal-cotizacion {
  padding: 41px 30px 25px;
}

.modal-box-channels {
  list-style: none;
  margin: 0;
  padding-left: 55px;
}

.modal-box-channels li {
  display: flex;
  gap: 9px;
  align-items: center;
  margin-bottom: 9px;
}

.modal-box-channels li label {
  font-size: 1.6rem;
  letter-spacing: -0.24px;
}

.modal-box-channel-heading {
  text-align: center;
  margin: 17px 0 17px 0;
}

.modal-box-channel-heading h2 {
  font-size: 1.6rem;
  font-weight: var(--semibold);
}

.modal-box-channel button {
  border: none;
  width: 100%;
  font-size: 1.5rem;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  font-weight: var(--semibold);
  color: var(--main-color);
  background-color: var(--secondary-color);

  &:disabled {
    background-color: var(--inactive-color);
    border: 1px solid var(--inactive-color);
  }
}

.modal-box-channel-message-content {
  text-align: center;
}

.modal-box-channel-message-content img {
  width: 14%;
  margin-bottom: 9px;
}

.modal-box-channel-message-content h2 {
  font-size: 1.7rem;
  letter-spacing: 0.17px;
  line-height: 23px;
  font-weight: var(--semibold);
}

.modal-box-channel-message-content {
  margin-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .cotizacion-generada-nota h2 {
    font-size: 1.4rem;
  }

  .cotizacion-generada-nota p {
    font-size: 1.4rem;
  }
  .cotizacion-generada-documento label {
    padding: 10px 50px;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .cotizacion-save-button .guardar_button {
    padding: 10px 85px;
  }
}

.alert-document {
  color: #ff2a40;
}
