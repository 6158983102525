.branchpicker-menu {
  background-color: var(--main-color);
  position: absolute;
  top: 0px;
  left: 0px !important;
  width: 320px;
  height: inherit;
  min-height: 100%;
  padding: 10px;
  box-shadow: 0px 3px 6px var(--shadow-color);
  text-align: center;
  z-index: 10001;
}
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.branchpicker-menu-off {
  display: none;
}

.list-branches {
  list-style: none;
  padding: 0;
  justify-content: flex-start;
}

.branch-element {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.branch-element img {
  width: 40%;
}

.branch-element p {
  font-size: 1.2em;
}
.sucursal-search {
  width: 100%;
  padding: 12px 18px;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  font-size: 1.041vw;
  color: #7e7e7e;
  outline: none;
  height: 45px;
}
.suc-search-icon {
  cursor: pointer;
  position: absolute;
  left: 81.5%;
  top: 56px;
  width: 20.51px;
}
.branch-search {
  width: 100%;
}
.action-table-cell-branch {
  font-size: 16px;
  margin-top: 10px;
  padding: 0px;
  display: flex;
  justify-content: initial;
  margin-left: 10px;
}
.list-branches .action-table-cell:hover {
  cursor: pointer;
}
.icon-arrow {
  position: relative;
  top: 10px;
  left: 125px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  color: #022767;
}
.icon-arrow:hover {
  color: #21c5df;
  cursor: pointer;
}
.icon-branch {
  height: 1.5em !important;
}
