/** @format */

.css-i4bv87-MuiSvgIcon-root {
  color: var(--secondary-color) !important;
}

.textModal {
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.17px;
  color: #010101;
  opacity: 1;
}

.modal-content {
  border-radius: 12px;
  border: none;
}
