/* .productos-cotizacion-right{
    max-width: 1800px;
} */

.products-cart {
  background-color: var(--main-color);
  box-shadow: 0px 1px 3px #15223214;
  min-height: 199px;
  min-width: 20%;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 5px;
}

.alert-cart-text h2 {
  font-weight: var(--semibold);
  text-align: center;
}

.customer-cart {
  margin-top: 10px;
  background-color: var(--main-color);
  padding: 20px 17px;
  box-shadow: 0px 1px 3px #15223214;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-cart-icons {
  display: flex;
  gap: 23px;
  margin-right: 27px;
  width: 57px;
  height: 40px;
  align-items: center;
}

.product-cart-products-display {
  padding: 18px 0px;
  overflow: hidden;
}

.product-cart-products-display h2 {
  text-align: center;
  font-size: 1.04vw;
  letter-spacing: 0.36px;
  color: #7e7e7e;
  font-weight: var(--semibold);
  margin-bottom: 30px;
}

.product-cart-products-inner {
  max-height: 50vh;
  padding: 0 -18px;
  overflow: scroll;
  overflow-x: hidden;
}

.product-cart-products-inner::-webkit-scrollbar {
  width: 2px;
}

.product-cart-products-inner::-webkit-scrollbar-thumb {
  background: lightgray;
}

.product-cart-inner {
  text-align: center;
  padding: 64px 50px;
}

.product-cart-inner h2 {
  font-size: 1.15vw;
  letter-spacing: 0.4px;
  margin-bottom: 18px;
  font-weight: 600;
}

.products-cart-buttons {
  margin: 6px;
  --bs-gutter-x: 2px !important;
  display: inline;
}

.products-cart-button-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.products-cart-button {
  border: none;
  font-size: 12px;
  padding: 10px 4px;
  border-radius: 5px;
  color: #afafaf;
  display: inline-block;
  width: 100%;
  align-items: center;
}

.products-cart-button-tooltip,
.approval-price-button-modal {
  border: 1px solid var(--secondary-color);
  border-color: var(--secondary-color);
  font-size: 1.3rem;
  padding: 11px 17px;
  border-radius: 5px;
  color: var(--secondary-color);
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  background-color: #ffff;
  font-size: 14px;
  font-family: 'Open Sans';
}

.button-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.products-cart-button-tooltip:enabled:hover,
.approval-price-button-modal.active {
  background-color: var(--secondary-color);
  color: #ffff;
}

.products-cart-button-tooltip:disabled {
  background-color: lightgray;
}
.approval-price-button-modal.disabled {
  color: #9b9b9b;
  border-color: var(--border-light-color);
  background-color: var(--border-light-color);
}

.products-cart-button-tooltip-filled {
  font-size: 1.3rem;
  padding: 11px 17px;
  border-radius: 5px;
  color: var(--main-color);
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  background-color: var(--secondary-color);
  font-size: 14px;
  font-family: 'Open Sans';
}

.products-cart-button-tooltip-filled:disabled {
  background-color: lightgray;
}

.products-cart-button:enabled {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.products-cart-button:enabled {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.fa-cart-shopping {
  font-size: 2.2em;
}

.expand:nth-child(1) {
  display: inline;
  min-width: 33%;
}

.expand:nth-child(2) {
  display: inline;
  min-width: 33%;
}

.expand:nth-child(3) {
  display: inline;
  min-width: 33%;
}

.links {
  text-decoration: none;
  color: #afafaf;
}

.links:hover {
  color: var(--main-color);
}

.product-cart-totals {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 0.83vw;
  padding: 0 18px;
  row-gap: 2rem;
}

.product-cart-product-generals div p {
  font-size: 0.92vw;
}
.product-cart-product-generals .top-text p {
  font-size: 0.83vw;
  /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
  overflow: hidden;
  line-height: 24px;
  letter-spacing: 0.32px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.final-price-cart {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.final-price-cart p {
  font-size: 0.65vw;
}

.product-cart-product-generals-amount {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-cart-product-generals-amount .multiply {
  font-size: 0.83vw;
}

.product-cart-product-generals-amount .edit {
  display: flex;
  align-items: center;
}

.product-cart-product-generals-amount .edit p {
  font-size: 0.65vw;
}

.counter {
  display: flex;
  color: var(--secondary-color);
  gap: 4px;
  align-items: center;
  font-size: 3rem;
}

.counter .number {
  font-size: 1.3rem;
  font-weight: var(--semibold);
  color: black;
  border-radius: 4px;
  border: 2px solid #eaebef;
  text-align: center;
  width: 52px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.customer-cart-items {
  width: 100%;
}

.customer-cart-items h4 {
  font-size: 0.87vw;
}

.customer-cotizacion-visible {
  display: block;
}

.customer-cotizacion-none {
  display: none;
}

.customer-cart-loading {
  display: flex;
  justify-content: center;
  margin-top: -40px;
}

.remove-total .trash {
  padding: 12px 21px;
  background-color: #fbfbfb;
  border-radius: 5px;
  cursor: pointer;
}

.remove-total {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.remove-total p {
  font-size: 1.6rem;
}

.remove-total .trash .fa-trash-can {
  font-size: 1.5rem;
}

.product-cart-product-generals .edit {
  display: flex;
  align-items: center;
  gap: 9px;
}

.product-cart-product-generals .edit p {
  font-size: 0.83vw;
}

.product-cart-product-generals .edit .fa-square-pen {
  font-size: 1.6rem;
}

.modal-especial .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.shopping-address-display {
  background-color: var(--main-color);
  margin-top: 6px;
  padding: 20px 17px;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shopping-address-display h2 {
  font-size: 0.92vw;
}

.address-bold {
  font-weight: var(--semibold);
}

.shopping-address-display .icons,
.customer-cart .icons {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 1.8rem;
}

.shopping-address-display .icons div,
.customer-cart .icons div {
  background-color: #fbfbfb;
  padding: 11px 19px;
  border-radius: 5px;
}

.shopping-address-display .icons div:hover,
.customer-cart .icons div:hover {
  cursor: pointer;
}

.shopping-address-display .icons div:nth-child(1),
.customer-cart .icons div:nth-child(1) {
  color: #3699ff;
}

.shopping-address-display .icons div:nth-child(2),
.customer-cart .icons div:nth-child(2) {
  color: #fb5c3c;
}

.complete-address {
  margin-bottom: 5px;
}

.box-generacion {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 408px;
  height: 201px;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
  padding: 30px 24px;
}

.box-generacion-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.box-generacion-body {
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 0.32px;
}

.box-generacion-body svg {
  color: var(--secondary-color);
  width: 2em;
  height: 1.5em;
}

.box-generacion-body p {
  font-weight: var(--semibold);
  line-height: 22px;
  margin-top: 15px;
}

.product-cart-total-numbers {
  text-align: right;
}

.product-cart-total-numbers p {
  font-size: 0.83vw;
}

.product-cart-total-text p {
  font-size: 0.83vw;
}

.container-shopping-cart {
  margin-bottom: 100px;
}

@media (max-width: 1725px) {
  .products-cart-buttons {
    flex-direction: column;
    --bs-gutter-y: 5px;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .products-cart-button {
    border: none;
    padding: 10px 4px;
    border-radius: 5px;
    color: #afafaf;
    display: inline-block;
    width: 100%;
    align-items: center;
  }
}

.modal-clase-choose-customer .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.modal-clase-choose-customer .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

/* SLIDER */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sliderMostrarDescuento {
  background-color: var(--main-color);
  border-radius: 5px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.sliderMostrarDescuento h3 {
  font-size: 16px;
}

.price-change-approval-item {
  display: flex;
  gap: 5px;
  margin-block: 24px;
  padding: 10px;
  border: 1px solid rgb(243, 243, 243);
  border-radius: 5px;
}

.price-change-approval-item .info p {
  font-size: 16px;
}
.price-change-approval-item .info p.name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .products-cart-button {
    font-size: 10px;
  }
}
