body {
    --table-line: rgba(204, 204, 204, 0.3);
  }
  
  .common-search-content {
    padding-top: var(--padding-top-bar);
    padding-left: var(--padding-left-bar);
    padding-bottom: 20px;
  }
  
  .common-search-header {
    padding: 15px 44px;
    background-color: var(--main-color);
    box-shadow: 0px 1px 4px #15223214;
  }
  
  .common-search-header h2 {
    font-size: 1.8rem;
    letter-spacing: 0.36px;
    color: var(--secondary-color);
  }
  
  .common-search-main {
    padding: 31px 63px 10px 63px;
  }
  
  .b-bttm {
    border-bottom: 1px solid var(--table-line);
  }
  
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 3px;
    align-items: center;
  }
  
  .pagination button {
    font-size: 1.2rem;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 9px 19px;
  }
  
  .table-buttons {
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .page-regular {
    border: 1px solid #dee2e6;
    background-color: var(--main-color);
  }
  
  .page-active {
    border: 1px solid var(--secondary-color);
    background-color: var(--secondary-color);
    color: var(--main-color);
  }
  
  .pagination-ellipsis {
    display: none;
  }
  
  .activeFilter {
    background-color: #3699ff;
    color: var(--main-color);
  }
  
  .create-button {
    text-decoration: none;
  }

  .modal-clase-edit-customer .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-clase-edit-customer .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .modal-clase-edit-customer .modal-content {
    height: auto;
    min-height: 100%;
    width: 100vw;
    border-radius: 0;
  }

  .search-bar {
    width: 100%;
    height: 45px;
    display: flex;
    gap: 6.8px;
    margin-bottom: 22px;
  }
  
  .search-bar input {
    padding: 12px 23px;
    width: 100%;
    border: 0.5px solid var(--inactive-color);
    outline: none;
    color: #7e7e7e;
    font-size: 0.93vw;
    letter-spacing: 0.36px;
    height: 45px;
  }
  
  .search-bar button {
    border: none;
    border-radius: 5px;
    font-size: clamp(12px, 14px, 17px);
    letter-spacing: 0.34px;
    background-color: var(--secondary-color);
    padding: 14px 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    color: var(--main-color);
    height: 100%;
  }
  
  .search-bar button img {
    margin-right: 6.8px;
  }
  
  .search-bar-input {
    position: relative;
    display: flex;
    width: 100%;
  }

  .cotizacion-search-icon {
    position: absolute;
    top: 27%;
    right: 1.8%;
    cursor: pointer;
  }
  
  .filter-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 17px;
  }
  
  .filter-buttons button {
    border: none;
    padding: 9px 18px;
    font-size: clamp(14px, 14px, 1.1vw);
    font-weight: 600;
    border-radius: 5px;
    letter-spacing: 0.32px;
  }

  .modal-header-cliente {
    padding: 0 63px;
    display: flex;
    justify-content: space-between;
  }