.my_list_body {
  padding-top: var(--padding-top-bar);
  padding-left: var(--padding-left-bar);
  overflow: hidden;
}

.lists_container {
  height: calc(100vh - var(--padding-top-bar));
  margin: 0;
}

.my_lists {
  background: var(--main-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--shadow-color);
  padding: 22px 48px;
  max-height: calc(100vh - var(--padding-top-bar));
  overflow-y: scroll;
}

.my_lists_container h2 {
  font-size: 18px;
  color: var(--secondary-color);
  letter-spacing: 0.36px;
}

.list_creation {
  display: flex;
  justify-content: space-between;
  padding: 15px 19px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-top: 25px;
  align-items: center;
}

.add_list_text {
  font-size: 16px;
  color: #a8a8a8;
  letter-spacing: 0.32px;
}

#add_list {
  min-width: 20px;
  min-height: 20px;
  background-color: var(--placeholder-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.3rem;
  color: #f3f3f3;
}

#add_list:hover {
  cursor: pointer;
}

.lists_types {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.list_type {
  display: flex;
  gap: 6px;
  align-items: center;
}

.list_type input[type='radio' i]:disabled {
  background-color: lightgray;
}

.list_type label {
  font-size: 14px;
}

.user_lists {
  list-style: none;
  padding: 0;
  margin-top: 12px;
}

.user_list,
.user_list_selected {
  display: flex;
  border: 1px solid #022767;
  color: var(--secondary-color);
  font-size: 16px;
  padding: 15px 22px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}

.list-name {
  display: flex;
  gap: 5px;
  align-items: center;
}

.user_list_selected {
  color: var(--main-color);
  background-color: #022767;
}

.products_list {
  background-color: var(--main-color);
  padding: 22px 45px;
}

.list-title h2 {
  font-size: 18px;
  color: var(--secondary-color);
  letter-spacing: 0.36px;
}

.list-search-bar {
  height: 45px;
  margin-top: 12px;
  display: flex;
  position: relative;
}

.list-search-bar input {
  width: 100%;
  height: 100%;
  padding: 14px 18px;
  font-size: 18px;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  outline: none;
}

.search-icon {
  width: 20px;
  position: absolute;
  right: 18px;
  top: 14px;
}

.select-all-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
  margin-top: 17px;
  font-size: 14px;
}

.add_list_input {
  margin-top: 5px;
  border-bottom: 0.5px solid rgba(211, 211, 211, 0.438);
}

.add_list_buttons {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.add_list_buttons button {
  flex-basis: 50%;
  border: none;
  font-size: 14px;
  padding: 13px 0;
  border: 1px solid #022767;
  border-radius: 5px;
}

.add_list_input input {
  width: 100%;
  padding: 14px 19px;
  border-radius: 5px;
  border: 1px solid var(--border-light-color);
  font-size: 16px;
  outline: none;
}

.add_list_continuar {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.add_list_continuar:disabled {
  background-color: lightgray;
  color: black;
  border: 1px solid lightgray;
}

.add_list_cancelar {
  background-color: var(--main-color);
  color: var(--secondary-color);
}

.product-search {
  padding: 0;
}

.my-new-list-products {
  list-style: none;
  padding: 0;
}

.product-from-list {
  font-size: 12px;
  height: 80px;
  display: flex;
  align-items: center;
  border: 0.5px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin: 15px 0;
}

.product-from-list-img {
  height: 100%;
  flex: 0 0 50%;
  text-align: center;
}

.product-from-list-text {
  flex: 0 0 50%;
}

.product-from-list-text h3 {
  font-size: 12px;
  max-lines: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-from-list-text p {
  font-size: 10px;
}

.product-from-list img {
  max-height: 100%;
}

.product-list-display-container {
  overflow-y: scroll;
  max-height: calc(100vh - 265px);
}

.add-list-to-quote {
  position: absolute;
  bottom: 10px;
  right: 45px;
}

.add-list-to-quote button {
  font-size: 14px;
  width: 245px;
  height: 44px;
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  color: white;
}

.add-list-to-quote button:disabled {
  background-color: lightgray;
  color: black;
  border: 1px solid lightgray;
}

.product-edit-list {
  font-size: 12px;
  height: 80px;
  display: flex;
  align-items: center;
  border: 0.5px solid lightgray;
  border-radius: 5px;
  padding: 10px;
  margin: 15px 0;
}

.product-edit-list-img {
  height: 100%;
  flex: 0 0 30%;
  text-align: center;
}

.product-edit-list-text {
  flex: 0 0 50%;
}

.product-edit-list-trash {
  flex: 0 0 20%;
  align-self: flex-end;
}

.product-edit-list-text h3 {
  font-size: 12px;
  max-lines: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-edit-list-text p {
  font-size: 10px;
}

.product-edit-list img {
  max-height: 100%;
}
