.dispatch-table-sell-order-topbar {
  background-color: #f8f9fa;
  border: 1px solid var(--border-light-color);
  padding: 43.5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  min-width: 1134px;
}

.dispatch-table-sell-order-search {
  width: 600px;
  position: relative;
}

.dispatch-table-sell-order-search .dispatch-table-sell-order-search-icon {
  position: absolute;
  top: 13px;
  right: 18px;
  width: 2.5%;
}

.dispatch-table-sell-order-table-style thead tr th,
.dispatch-action-table thead tr th {
  font-weight: var(--semibold);
}

.dispatch-table-sell-order-filter-button {
  font-size: 2rem;
  width: 47px;
  height: 40px;
  color: var(--main-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
}

.dispatch-table-sell-order-filter-options {
  position: relative;
}

.dispatch-table-sell-order-filtes {
  position: absolute;
  width: 162px;
  background-color: var(--main-color);
  font-size: 12px;
  box-shadow: 0px 3px 6px var(--shadow-color);
  border-radius: 5px;
  z-index: 2;
}

.dispatch-table-sell-filter-option {
  padding: 11px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dispatch-table-sell-filter-option input[type='checkbox'] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.dispatch-table-sell-order-table-style {
  width: 100%;
  min-width: 1134px;
}

.dispatch-table-sell-order-table-style th {
  font-size: 14px;
  padding: 12px 14px;
  border: 1px solid var(--border-light-color);
  border-top: none;
  background-color: #f8f9fa;
}

.dispatch-table-sell-order-table-style tr {
  font-size: 14px;
  border-bottom: 1px solid var(--border-light-color);
}

.dispatch-table-sell-order-table-style td {
  padding: 21px 23px;
  border: 1px solid var(--border-light-color);
  border-bottom: none;
  border-top: none;
}

.dispatch-table-order-check-style {
  justify-content: center;
  display: flex;
}

.dispatch-table-sell-order-table-style thead {
  position: sticky;
  top: 0;
}

.dispatch-tableContainer {
  height: 50vh;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  border-bottom: 1px solid var(--border-light-color);
}

.dispatch-tableContainer-sp {
  max-height: 30vh;
  overflow-y: scroll;
  position: relative;
  border-bottom: 1px solid var(--border-light-color);
}
.dispatch-stickyFooter {
  position: sticky;
  bottom: 0;
  background: #f8f9fa;
  border-top: 1px solid var(--border-light-color);
}

.dispatch-product-resume__wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 21px;
}
.dispatch-product-resume__wrapper .dispatch-product-resume__item {
  padding: 21px 23px;
}

.dispatch-arrow-order-style {
  margin-left: 9px;
}

.dispatch-order-header-table-container {
  display: flex;

  justify-content: space-between;
}

.dispatch-order-header-text {
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
}
.dispatch-order-table-text {
  font-size: 14px;
}

.dispatch-table-container {
  overflow-x: scroll;
}

.dispatch-table-container::-webkit-scrollbar {
  display: none;
}
