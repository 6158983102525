.modal-box-update-precio {
  width: 382px;
  min-height: 387px;
  border-radius: 15px;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
  position: fixed;
  top: 25vh;
}

.edit-precio-modal-container {
  padding: 27px 20px;
  background-color: var(--main-color);
}

.modal-box-update-precio .edit-precio-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-modal {
  font-size: 20px;
}

.close-modal:hover {
  cursor: pointer;
}

.modal-box-update-precio .edit-precio-modal-header h2 {
  margin: 0;
  line-height: 28px;
  color: #010101;
  font-weight: var(--semibold);
  font-size: 1.6rem;
  letter-spacing: 0.16px;
}

.modal-box-update-precio .edit-precio-modal-header p {
  margin: 0;
  line-height: 28px;
  color: #010101;
  font-weight: 600;
}

.edit-precio-modal-body {
  margin-top: 36px;
  align-items: center;
  text-align: center;
}

.edit-precio-modal-body p {
  font-size: 1.18vw;
  color: #010101;
}

.edit-precio-modal-body .razones-button {
  margin-top: 10px;
  font-size: 1.4rem;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  letter-spacing: 0.34px;
  background-color: var(--main-color);
  padding: 8px 31px;
  width: 100%;
  align-items: center;
  color: var(--secondary-color);
  margin-bottom: 32px;
}

.modal-box-update-precio input {
  padding: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 0 8px 8px 0;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.18vw;
  font-weight: 400;
}

.modal-box-update-precio .modal-buttons {
  margin-top: 25px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.modal-box-update-precio .modal-buttons .btn-cambiar {
  font-family: inherit;
  outline: none;
  background-color: var(--secondary-color);
  color: #fff;
  border-radius: 5px;
  font-size: 1.4rem;
  padding: 10px 30px;
  border: none;
}

.edit-precio-modal-body .toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-precio-modal-body .toggle p {
  font-size: 1.6rem;
  letter-spacing: 0.16px;
}

.toggle .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 6px;
  bottom: 6px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--secondary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(25px);
}

.modal-box-update-razones {
  position: relative;
  top: 90px;
  left: -195px;
  max-height: 100%;
  width: 435px;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
}
@media screen and (max-width: 993px) {
  .modal-box-update-razones {
    left: 15px;
  }
}
@media screen and (min-width: 994px) and (max-width: 1320px) {
  .modal-box-update-razones {
    left: -150px;
  }
}

.razones-modal-container {
  padding: 29px 24px;
}

.razones-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.razones-modal-header .close-modal img {
  width: 15px;
}

.razones-modal-header h2 {
  font-weight: var(--semibold);
  font-size: 1.6rem;
  letter-spacing: 0.16px;
}

.razones-modal-body {
  align-items: center;
  margin-top: 15px;
  text-align: center;
}

.razones-modal-body .razones-names {
  font-size: 1.3rem;
  color: #010101;
}

.razones-modal-body .modal-buttons {
  margin-top: 25px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.razones-modal-body .modal-buttons .btn-continuar {
  font-family: inherit;
  outline: none;
  background-color: var(--secondary-color);
  color: #fff;
  border-radius: 5px;
  font-size: 17px;
  padding: 10px 30px;
}

.razones-option {
  background-color: #f3f3f3;
  border-radius: 5px;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}

.razon-text {
  text-align: left;
}

.razon-text label {
  font-size: 15px;
  letter-spacing: 0.16px;
  line-height: 22px;
}

.razon-text p {
  font-size: 15px;
  letter-spacing: 0.14px;
  line-height: 19px;
  font-weight: var(--semibold);
}

.razones-option .fa-check {
  /* display: none; */
  background-color: var(--secondary-color);
  color: var(--main-color);
  padding: 5px;
  border-radius: 50%;
}

.razones-options-buttons {
  display: flex;
  align-items: center;
}

.razones-option input {
  height: 20px;
  width: 20px;
}

.razones-options.selected input {
  opacity: 0;
  display: none;
  cursor: pointer;
  height: 0;
  width: 0;
}

.razones-options.selected .fa-check {
  display: block;
  border-radius: 50%;
  padding: 3px;
  width: 12px;
  height: 12px;
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.razones-options.selected {
  border: 2px dotted var(--secondary-color);
  background-color: var(--main-color);
}

.razones-options.selected > label {
  color: var(--secondary-color);
}

.razon-selected {
  border: 1px solid var(--secondary-color);
  background-color: var(--main-color);
  color: var(--secondary-color);
}

.margin-error-message {
  color: red;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 10px;
}

.currency-input {
  display: flex;
  margin-top: 15px;
}

.currency-input .price-sign {
  font-size: 1.18vw;
  width: 15%;
  border: none;
  border-radius: 8px 0 0 8px;
}

.modal-price-change-allow {
  background-color: var(--main-color);
  padding: 19px 42px 75px 42px;
  text-align: right;
  box-shadow: 0px 3px 6px var(--shadow-color);
  border-radius: 15px;
}

.price-allow-text {
  text-align: center;
  margin-top: 35px;
}

.price-allow-buttons {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.price-allow-buttons button {
  border-radius: 5px;
  border: none;
  font-size: 1.4vw;
  width: 52px;
  height: 47px;
  border: 1px solid var(--secondary-color);
}

.price-allow-true {
  background-color: var(--secondary-color);
  color: var(--main-color);
}

.price-allow-false {
  background-color: var(--main-color);
}

.price-allow-text h2 {
  font-weight: var(--semibold);
}
