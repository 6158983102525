.user-menu {
  background-color: white;
  position: absolute;
  width: 284px;
  top: 70px;
  right: 15px;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.user-menu ul {
  list-style: none;
  margin: 0;
  padding: 43px 50px 20px 47px;
}

.user-menu-off {
  display: none;
}

.user-nav-title {
  font-size: 1.8em;
  font-weight: 600;
  margin: 0;
}

.user-menu ul li:nth-child(1) .user-nav-text,
.user-menu ul li:nth-child(2) .user-nav-text {
  margin-left: 19px;
}

.user-nav-item {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
}

.user-nav-item img {
  width: 26.5px;
}

.user-nav-subtext {
  font-size: 1.4em;
  color: #afafaf;
}

.user-nav-button {
  width: 100%;
  text-align: center;
  padding: 0 8px;
  margin-bottom: 22px;
}

.user-nav-button button {
  background-color: var(--main-color);
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  border-radius: 5px;
  font-size: 1.6em;
  padding: 8px 50px;
}
