.vad-cards-deals__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  column-gap: 20px;
  row-gap: 30px;
}

@media screen and (max-width: 1850px) {
  .vad-cards-deals_container {
    column-gap: 5px;
  }
}
@media screen and (max-width: 1370px) {
  .vad-cards-deals_container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 26px;
  }
}
@media screen and (max-width: 1250px) {
  .vad-cards-deals_container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 26px;
  }
}
@media screen and (max-width: 900px) {
  .vad-cards-deals_container {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 26px;
  }
}
