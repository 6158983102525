.printer-modal-root-wrapper {
  color:var(--secondary-color);
  min-width: 580px;
}
.printer-modal-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 24px 24px 44px;
}
.printer-modal-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.printer-modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.printer-modal-content-title {
  font-size: 16px;
  font-weight: 600;
}
.printer-modal-content-button {
  width: 220px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary-color);
  border-radius: 5px;
  color: white;
  font-size: 14px;
  outline: none;
  border: none;
  margin-top: 10px;
}
.printer-modal-content-button:hover {
  opacity: 0.95;
}
.printer-modal-content-button:disabled {
  background: #dfdfdf;
  color: #757474;
}

.printer-modal-content-option-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.printer-modal-content-option {
  border: none;
  outline: none;
  border-radius: 5px;
  width: 100px;
  max-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  gap: 5px;
}
.printer-modal-content-option:disabled {
  color: var(--secondary-color)
  
}
.printer-modal-content-image-wrapper {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
}
.printer-modal-content-option:disabled .printer-modal-content-image-wrapper {
  background: #f3f3f3;
}
.printer-modal-content-image {
  width: 53px;
  height: 53px;
  object-fit: cover;
}
.printer-modal-content-text {
  font-size: 12px;
}
