@media screen and (max-width: 1250px) {
  .vad-card-deal__container .vad-card-deal__wrapper {
    width: 100%;
    max-width: unset;
  }
}

.productCardImage {
  padding: 20px 20px;
  height: 60%;
  width: 100%;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
