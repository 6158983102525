.modal-materials-calculator {

    width: 100%;
    z-index: 99999;
}

.container-main {
    width: 100%;
    background-color: white;
    outline: none;
    min-height: 100vh;
}

.padding {
  padding: 40px;
  width: 100%;
  padding-left: 100px;
  padding-top: 85px;
}

.container-inputs {
    display: flex;
    flex-direction: column;
}

.container-main-modalcalculator {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.container-inputs-formula {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    margin-bottom: 10px;
}

.descriptive-container, .calculation-container {
    padding: 25px;
    opacity: 1;
    min-height: 50vh;
}

.border-line {
  border: 1px solid #A7A8AC;
}


.title {
    font-size: 27px;
    color: var(--secondary-color);
    margin-bottom: 10px;
}

.text-description {
    font-size: 16px;
}

.descriptive-container img {
    max-width: 100%;
    height: auto;
}

.icon-close {
    display: flex;
    justify-content: flex-end;

}

.margin-icon-close-plp {
  margin-bottom: 20px
}
.margin-icon-close-pdp {
  padding-top: 30px
}

.image-description {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.container-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

.select-calculator {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('arrow-down.png') no-repeat right center;
    background-size: 16px; /* Ajusta el tamaño según tu imagen */
    padding-right: 20px; /* Espacio para la flecha */
    outline: none;
    padding: 8px 16px;
    transition: background 0.3s ease, transform 0.3s ease;
    position: relative;
    height: 45px;
    /* UI Properties */
    border: 1px solid #C8C8C8;
    border-radius: 5px;
    font-size: 14px;
    opacity: 1;
    width: 100%;
  }
  .calculator-selector-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

  }
  .reset-selector-button {
    position: absolute;
    right: 2px;
    height: 90%;
    border: none;
    outline: none;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    border-radius: 4px;
    z-index: 999;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-counter-buttons {
    position: absolute;
    right: 8px;
    height: 90%;
    border: none;
    outline: none;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    border-radius: 4px;
    z-index: 999;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-control:disabled {
    background-color: var(--main-color);
  }
  .reset-selector-button:hover {
    background: #f1f1f1;
  }
  
  /* Contenedor para la flecha */
  .select-calculator::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px; /* Ajusta según el tamaño de tu imagen */
    height: 16px; /* Ajusta según el tamaño de tu imagen */
    background: url('arrow-down.png') no-repeat center center;
    background-size: contain;
    transition: transform 0.3s ease;
  }
  
  /* Estilo cuando el select está enfocado */
  .select-calculator:focus::after {
    transform: translateY(-50%) rotate(180deg);
  }

.calculator-buttons button {
    padding: 13px 30px;
    font-size: 1.4rem;
    border-radius: 5px;
  }
.calculator-buttons-formula {
    font-size: 1.2rem;
    border-radius: 5px;
    padding: 3.5px 20px;
  }
  .calculator-buttons-clear {
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
    background-color: var(--main-color);
  }

  .container-calculation-formula {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
    background-color: var(--main-color);
  }
  
  .container-calculation-formula p {
    font-weight: bold;
  }
  .container-calculation-formula span {
    font-weight: 400;
  }

  .container-results-formula {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .calculator-buttons-calculate, .calculator-buttons-formula {
    border: 1px solid var(--secondary-color);
    color: var(--main-color);
    background-color: var(--secondary-color);
  }

  .container-measures {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .scrollable-container {
    max-height: calc(100vh - var(--padding-top-bar)); /* Ajusta la altura máxima según tus necesidades */
    overflow-y: auto; /* Asegura el desplazamiento vertical */
  }

  .alert-cart-text {
    max-width: 500px;
    text-align: center;
  }

  .container-palette {
    border-radius: 5px !important;
    width: 48px !important;
    height: 35px !important;
  }

  .border-selected {
    border: 3px solid var(--secondary-color) !important;
  }
  .border-normal {
    border: 1px solid #000 !important;
  }