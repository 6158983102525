.box-generacion {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 408px;
  height: 201px;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
  padding: 30px 24px;
}

.box-generacion-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.box-generacion-body {
  text-align: center;
  font-size: 1.6rem;
  letter-spacing: 0.32px;
}
