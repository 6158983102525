.calculator-option-home-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 509px;
  max-height: 90vh;
  border-radius: 15px;
  background-color: var(--main-color);
  outline: none;
  box-shadow: 0px 3px 6px var(--dark-color-1);
  padding: 36px 23px;
  overflow-y: scroll;
}

.calculator-option-home-modal::-webkit-scrollbar {
  display: none;
}

.calculator-option-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calculator-lamina-option .selected {
  background-color: var(--secondary-color);
  color: var(--main-color);
}
.selected {
  background-color: var(--secondary-color);
  color: var(--main-color);
  border-radius: 5px;
}

.calculator-option-home h2 {
  letter-spacing: 0.16px;
  font-size: 1.6rem;
  font-weight: var(--semibold);
}

.calculator-data-home {
  display: flex;
  gap: 33px;
  margin-top: 44px;
  align-items: center;
}

.calculator-data-home input {
  border: 1px solid var(--inactive-color);
  border-radius: 5px;
  font-size: 1.6rem;
  letter-spacing: 0.32px;
  padding: 16px 22px;
  max-width: 185px;
  outline: none;
}

.calculator-buttons-home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 54px;
}

.calculator-buttons-home button {
  border-radius: 5px;
  padding: 13px 0;
  font-size: 1.4rem;
}

.pvcErrorMessage {
  padding-top: 30px;
  color: var(--error-color);
}
