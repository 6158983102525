.product-carrousel-card {
  position: relative;
  max-width: 224px;
  height: 350px;
  background-color: var(--main-color);
  padding: 10px;
  border: 1px solid #dbdbdb;
  margin-bottom: 10px;
  margin-top: 10px;
}

.product-card-empty {
  width: 224px;
  height: 320px;
  background-color: transparent;
}
.product-carrousel-card-img {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}

.product-carrousel-card-img img {
  width: 100%;
  height: 140px;
  object-fit: contain;
}

.product-carrousel-card-generals {
  padding: 0 14px;
}

.product-carrousel-card-generals p {
  /* font-size: 0.69vw; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-carrousel-card-generals-sku {
  margin-top: 3px;
  /* font-size: 0.69vw; */
}

.product-carrousel-card-generals-name {
  /* font-size: 0.84vw !important; */
  font-family: 'Open Sans' !important;
  margin-top: 3px;
}

.product-carrousel-card-generals-price {
  /* font-size: 1.17vw !important; */
  font-weight: var(--semibold);
  margin-top: 5px;
}

.product-carrousel-card-button {
  height: calc(100% - 80%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.product-carrousel-card-button button {
  position: absolute;
  bottom: 0;
  border: none;
  background: var(--main-color);
  font-size: 10px;
  color: var(--secondary-color);
  width: 100%;
  border-radius: 5px;
  padding: 10px;
}

.product-carrousel-card-button button:hover {
  background: var(--secondary-color);
  color: var(--main-color);
}

@media screen and (min-width: 1800px) {
  .product-carrousel-card {
    width: 224px;
    height: 360px;
    background-color: var(--main-color);
  }
}
