.no-margin-nopadding {
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.dispatch-type-navbar-container {
  height: 100%;
  justify-content: space-between;
  z-index: 10;
  background-color: var(--main-color);
  position: fixed;
  right: 0;
  top: 65px;
  transition: 200ms linear;
  padding: 36px 37px 0px;
  width: 491px;
  box-shadow: -4px 0px 12px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -4px 0px 12px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -4px 0px 12px -4px rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  overflow-x: hidden;
}

.dispatch-type-navbar-container::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.generic-date-picker-styles {
  text-transform: capitalize;
  font-size: 16px;
  width: 100%;
}

.generic-date-picker-popper-styles,
.MuiPickersPopper-root {
  z-index: 999999999 !important;
}

.generic-date-input-styles {
  height: 52px !important;
  padding: 0px 0px 0px 17px !important;
  font-size: 16px !important;
}

.col-distribute-container {
  display: flex;
  justify-content: space-between;
}

.client-detail-forms-distribution {
  display: block;
}

.generate-dispatch-button-distribution {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  margin-bottom: 65px;
  padding: 0;
  margin-top: 30px;
}

.dispatch-type-close {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.dispatch-type-title {
  color: var(--secondary-color);
  font-size: 18px;
  margin: 0px 0px 20px 18px;
  width: 80%;
}

.close-icon-dispach-menu {
  margin-right: 8px;
}

.delivery-button-style-active {
  border: none;
  width: 190px;
  display: flex;
  background-color: var(--secondary-color);
  color: var(--main-color);
  padding: 10px 21px;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  height: 50px;
  letter-spacing: 0.34px;
  font-size: 1rem;
}

.delivery-button-style-inactive {
  width: 190px;
  display: flex;
  border: 1px solid var(--inactive-color);
  background-color: var(--main-color);
  color: var(--background-color);
  padding: 10px 21px;
  outline: 0;
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  letter-spacing: 0.34px;
  font-size: 1rem;
}

.delivery-button-container {
  display: flex;
  gap: 22px;
  justify-content: space-around;
  padding-left: 8px;
  width: max-content;
}

.delivery-method-img {
  width: 27px;
  height: 27px;
  fill: var(--main-color);
}

.delivery-method-img-inactive {
  width: 27px;
  height: 27px;
  fill: var(--inactive-color);
}

.delivery-type-title {
  font-size: 15px;
  font-weight: bold;
  margin: 15px 0px 15px 0px;
}

.delivery-location-title {
  font-size: 15px;
  font-weight: bold;
  margin: 30px 0px 11px 0px;
}

/* /////////////////delivery active /////////// */

.check-circle-active {
  color: var(--secondary-color-light);
  width: 27px;
  height: 27px;
}

.pinpoint-location-active {
  color: var(--location-color-red);
  height: auto;
  margin-right: 3px;
  width: 18px;
}

.direction-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9px 0;
}

.delivery-method-text {
  color: var(--main-color);
}

.delivery-method-text-inactive {
  color: var(--inactive-color);
}

.direction-text-style {
  display: initial;
  font-size: 15px;
  width: 100%;
  overflow: hidden;
  color: var(--secondary-color-light);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.direction-button-style-active {
  width: 195px;
  display: flex;
  border: 2px dotted var(--secondary-color-light);
  background-color: var(--main-color);
  color: var(--background-color);
  outline: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 12px;
  font-size: 1rem;
}

.direction-location-container {
  justify-content: center;
  display: flex;
  width: 70%;
  align-items: center;
  align-content: center;
}

.sell-order-column-width {
  width: 30%;
}

/* /////////////////delivery inactive /////////// */

.check-circle-inactive {
  color: var(--inactive-color);
  width: 23px;
  height: 23px;
  border: 1px solid var(--inactive-color);
  border-radius: 20px;
}

.pinpoint-location-inactive {
  color: var(--inactive-color);
  height: auto;
  margin-right: 3px;
  width: 18px;
}

.direction-button-container-inactive {
  display: flex;
  justify-content: space-around;
  margin-top: 17px;
}

.direction-text-style-inactive {
  display: initial;
  font-size: 15px;
  width: max-content;
  overflow: hidden;
  color: var(--inactive-color);
  text-overflow: ellipsis;
  white-space: nowrap;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.direction-button-style-inactive {
  width: 195px;
  display: flex;
  border: none;
  background-color: var(--inactive-color-light);
  color: var(--background-color);
  outline: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 12px;
  letter-spacing: 0.34px;
  font-size: 1rem;
}

.add-direction-container {
  width: 96%;
  height: 50px;
  align-items: center;
  background-color: #f3f3f3;
  margin: 20px 0px 0px 10px;
  justify-content: space-between;
  cursor: pointer;
}

.add-direction-option {
  width: 25%;
}

.add-direction-plus {
  width: 29px;
  height: 29px;
  background-color: var(--placeholder-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.7rem;
  color: #f3f3f3;
}

.add-direction-label-col {
  display: flex;
  justify-content: flex-start;
  padding-left: 23.49px;
}

.add-direction-plus-sign-col {
  display: flex;
  justify-content: flex-end;
  padding-right: 26px;
}

.add-direction-label-text {
  color: var(--placeholder-color);
  font-size: 15px;
  width: max-content;
}

.generic-address-filter-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 1rem;
  height: 100%;
  padding: 10px 30px;
  border: none;
  background-color: transparent;
}

.generic-address-search-filter {
  display: flex;
  width: 100%;
  position: relative;
  height: 52px;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  background-color: transparent;
}

.generic-address-filter-options {
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  padding: 0;
  overflow: scroll;
  max-height: 220px !important;
  transition: all 0.1s;
  box-shadow: 0px 3px 6px var(--shadow-color);
}

.generic-address-filter-options-hidden {
  max-height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  z-index: 1;
  background-color: var(--main-color);
  width: 100%;
  border-radius: 0 0 5px 5px;
  list-style: none;
  padding: 0;
  transition: all 0.1s;
}

.generic-address-filter-options .generic-address-filter-option:hover {
  background-color: var(--secondary-color);
  color: var(--main-color);
  font-weight: var(--semibold);
  cursor: pointer;
}

.generic-address-filter-option label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
}

.generic-address-filter-option .radio {
  display: none;
}

.generic-address-filter-option {
  font-size: 12px;
  padding: 5px 30px;
}

.generic-address-label-placeholder {
  color: var(--placeholder-color);
  font-size: 16px;
}

.generic-address-label-text {
  font-size: 15px;
  color: var(--dark);
}

.generic-address-title-text {
  font-size: 15px;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 16px;
  font-weight: bold;
}

.generic-address-father-container {
  margin: 0px;
}

.add-delivery-superior-margin,
.add-sucursal-superior-margin,
.select-supplier {
  margin-top: 27.5px;
}

.message-billing-branch {
  margin-top: 5px;
}
.add-sucursal-superior-margin {
  margin-top: 27.5px;
}

.message-billing-branch {
  margin-top: 5px;
}

.row-no-margin {
  margin: 0px;
}

.generate-dispatch-button-container {
  width: 100%;
  text-align: end;
  margin-top: 18px;
  margin-bottom: 30px;
  padding: 0;
}

.generate-dispatch-button-footer {
  border: none;
  min-width: 150px;
  background-color: var(--secondary-color);
  color: var(--main-color);
  padding: 13px 31px;
  border-radius: 5px;
  max-height: 44px;
  letter-spacing: 0.34px;
  max-lines: 1;
  margin: 2px;
  font-size: 1.4rem;

  &:disabled {
    background-color: var(--inactive-color);
  }
}

.add-delivery-error-on-loading {
  color: var(--error-color);
}

.generic-address-down-icon {
  color: var(--secondary-color);
}
.margin-label-sell-method {
  margin-left: 30px !important;
}

.generic-date-picker-popper-styles .MuiPickersDay-root {
  font-size: 16px;
}
.generic-date-picker-popper-styles .MuiPickersCalendarHeader-label {
  font-size: 16px;
}
.generic-date-picker-popper-styles .MuiPickersCalendarHeader-switchViewIcon {
  font-size: 16px;
}
.generic-date-picker-popper-styles .MuiPickersArrowSwitcher-button {
  font-size: 16px;
}
.generic-date-picker-popper-styles .MuiDayPicker-header {
  font-size: 16px;
}
.generic-date-picker-popper-styles .MuiPickersCalendarHeader-root {
  font-size: 16px;
}
.generic-date-picker-popper-styles .MuiYearPicker-root {
  font-size: 16px;
}

.see-more-address {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  color: var(--secondary-color);
  cursor: pointer;
  padding: 0;
  margin: 4px 0px 0px 5px;
}

.address-option-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.tooltip {
  position: absolute;
  background: #fff;
  color: black;
  visibility: hidden;
  padding: 10px;
  border-radius: 5px;
  bottom: 80%;
  left: 8px;
  width: 195px;
  opacity: 1;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 12px;
}

.address-edit-button {
  padding: 0;
  text-decoration: underline;
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 12px;
  margin-left: 6px;
  border: none;
  outline: none;
  background: white;
}
