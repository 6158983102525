body {
  background-color: inherit;
  --open-quotes: #11d29e;
  font-family: 'Open Sans';
}

.home-content {
  padding-top: calc(var(--padding-top-bar) + 25px);
  padding-left: calc(var(--padding-left-bar) + 17px);
  padding-right: 17px;
}

.quotes-and-clients {
  display: flex;
  gap: 7px;
}

.text-no-records-activities {
  padding: 10px;
  text-align: center;
}

.quotes-info-left {
  background-color: var(--main-color);
  border-radius: 5px;
  color: var(--secondary-color);
  display: flex;
  padding: 1.45vw 2.03vw;
  height: 162px;
  width: 100%;
  text-align: left;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 20px;
  line-height: 27px;
  font-family: 'Open Sans', sans-serif !important;
  margin-bottom: 20px !important;
  margin-left: 0px !important;
}


.quotes-info-right {
  background-color: var(--main-color);
  border-radius: 5px;
  color: var(--secondary-color);
  display: flex;
  padding: 1.45vw 2.03vw;
  height: 162px;
  width: 100%;
  text-align: left;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 20px;
  line-height: 27px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px !important;
  margin-left: 0px !important;
}


.quotes-info-left h2,
.quotes-info-right h2 {
  font-size: 20px;
  letter-spacing: 0.25px;
  margin: 0;
}

.quotes-info-left p,
.quotes-info-right p {
  font-size: 50px;
  letter-spacing: 0.5px;
}

.my-quotes {
  margin-top: 0px;
  margin-bottom: 15px;
  background-color: var(--main-color);
  padding: 27px 29px;
  box-shadow: 0px 1px 4px #15223214;
  height: auto;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  box-shadow: 0px 3px 10px #00000014;
  border-radius: 8px;
  /* border-bottom: 1px solid red; */
  /* height: calc(100vh - 21vh); */
  /* overflow: -moz-hidden-unscrollable; */
}

.my-quotes-header,
.my-quotes-header > a > h3 {
  margin-bottom: 1.5625vw;
  color: var(--dark) !important;
  margin-left: 10px;
  font-size: 21px;
  font-weight: 100;
}

.my-quotes-header h2 {
  font-size: 1.45vw;
  margin: 0;
  font-weight: 600;
}

.quotes-btn {
  border: none;
  font-size: clamp(14px, 0.83vw, 14px);
  background-color: var(--secondary-color);
  color: var(--main-color);
  font-weight: 400;
  font-family: 'Open Sans';
  padding: 13px 98px;
  border-radius: 5px;
  max-height: 44px;
}

.all-quotes {
  display: flex;
  justify-content: flex-end;
  margin-top: 11px;
}

.option {
  background-color: var(--main-color);
  /* box-shadow: 0px 1px 3px #15223214;
    border-radius: 5px 5px 0px 0px; */
  padding: 2.3vw 1.42vw;
  color: var(--secondary-color);
  transition: 50ms linear;
  width: 100%;
  height: 315px !important;
  top: 290px;
  left: 90px;
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  box-shadow: 0px 3px 10px #00000014;
  border-radius: 8px;
  opacity: 1;
}

.option h2 {
  margin: 7px 0 0 0;
  font-size: 1.1vw;
  letter-spacing: 0.25px;
  font-weight: 600;
}

.option p {
  font-size: 16px;
  font-weight: 100;
  margin: 9px 0 0 0;
}

/* .option:hover {
  background-color: var(--secondary-color);
  cursor: pointer;
} */

/* .option:hover h2,
.option:hover p {
  color: var(--main-color);
} */

.option:hover img {
  filter: invert(98%) sepia(91%) saturate(2%) hue-rotate(254deg)
    brightness(107%) contrast(100%);
}

.modal-calculadora {
  max-height: 100%;
  border-radius: 15px;
  background-color: white;
  outline: none;
  box-shadow: 0px 3px 6px var(--shadow-color);
  padding: 30px 60px;
}

.modal-calculadora-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-calculadora-header h2 {
  font-size: 0.83vw;
  letter-spacing: 0.16px;
  font-weight: var(--semibold);
}

.modal-calculadora-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 30px 20px 30px;
}

.modal-calculadora-options > div {
  flex: 1 1 50%; /* Para obtener dos columnas con tamaño igual */
  /* Otros estilos necesarios para cada elemento */
}

.modal-calculadora-options .calculator-option {
  max-height: 289px;
  width: 339px;
  cursor: pointer;
}

.modal-calculadora-options .calculator-option h2 {
  font-size: 1.3vw;
  letter-spacing: 0.25px;
  line-height: 34px;
  font-weight: var(--semibold);
}
.top-layout-container {
  /* Layout Properties */
  top: 102px;
  left: 90px;
  width: 100% !important;
  height: 162px !important;
  /* UI Properties */
  background: transparent linear-gradient(262deg, #003082 0%, #0778ce 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 20px !important;
}
.img-icon-option-yellow {
  width: 58px !important;
  border-radius: 31px;
  border: 0px solid #f7b5008c;
  background-color: #f7b5008c;
  height: 57px;
  padding: 10px;
}
.img-icon-option-purple {
  width: 58px !important;
  border-radius: 31px;
  border: 0px solid #eeedfc;
  background-color: #eeedfc;
  height: 57px;
  padding: 10px;
}
.img-calculator {
  display: block;
  margin: auto;
  z-index: 100000;
  padding: 2px;
}
.label-option {
  height:57px;
  text-align: left;
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
  line-height: 25px;
  font-family: 'Open Sans', sans-serif !important;
  letter-spacing: 0.23px;
  color: #003082;
  opacity: 1;
  margin-bottom: 15px !important;
}


.second-layout {
  height: 645px;
}
.remove-decorations {
  text-decoration: none;
}

.wrapper-stepper {
  width: auto;
  height: auto;
}

.circle-stepper {
  width: 30px;
  height: 30px;
  background: var(--main-color);
  border-radius: 100px;
  border: 7px solid;
  position: relative;
  margin: 0px 100px 0 30px;
}

.circle-stepper > h5 {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #fff;
}

.vertical-line-stepper {
  width: 2px;
  height: 70px;
  background: #00000014;
  margin: 0px 100px 0 44px;
}
.vertical-line-stepper-hidden {
  width: 2px;
  height: 70px;
  margin: 0px 100px 0 44px;
  display: table-footer-group !important;
}

.hr-stepper {
  border: 0;
  height: 1px;
  background: #333;
  background: linear-gradient(to right, #ccc, #333, #ccc);
}
.information-quotation {
  position: relative;
  top: -103px;
  left: 90px;
  height: 0px;
}
.p-date {
  font-size: 14px;
  color: #767676;
}
.quotation-number {
  color: var(--dark);
  font-size: 18px !important;
  font-weight: 500;
}
.no-stats-results-text {
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .modal-calculadora-header h2 {
    font-size: 2vw;
  }
  .modal-calculadora-options {
    padding: 0px;
  }

  .quotes-info-left h2,
  .quotes-info-right h2 {
    font-size: 20px !important;
  }
  .quotes-info-left p,
  .quotes-info-right p {
    font-size: 50px;
    letter-spacing: 0.5px;
  }
  .quotation-number {
    font-size: 14px !important;
  }
  .label-option {
    font-size: 12px !important;
  }
  .option {
    width: 100%;
    height: 225px !important;
  }
  .option p {
    font-size: 12px;
  }
  .img-icon-option-purple,
  .img-icon-option-yellow {
    width: 45px !important;
    height: 45px !important;
  }
  .second-layout {
    height: 467px;
  }
  .circle-stepper {
    width: 20px;
    height: 20px;
    background: var(--main-color);
    border-radius: 100px;
    border: 4px solid;
    position: relative;
    margin: 0px 100px 0 30px;
  }

  .vertical-line-stepper {
    width: 2px;
    height: 40px;
    background: #00000014;
    margin: 0px 100px 0 39px;
  }
  .vertical-line-stepper-hidden {
    width: 2px;
    height: 40px;
    margin: 0px 100px 0 39px;
    display: table-footer-group !important;
  }
  .information-quotation {
    position: relative;
    top: -64px;
    left: 90px;
    height: 0px;
  }
  .quotation-number {
    color: var(--secondary-color);
    font-size: 15px !important;
  }
  .p-date {
    font-size: 13px;
  }
}
@media only screen and (min-width: 770px) and (max-width: 1199px) {
  .modal-calculadora-header h2 {
    font-size: 2vw;
  }
  .modal-calculadora-options {
    padding: 0px;
  }
  .quotes-info-left h2,
  .quotes-info-right h2 {
    font-size: 20px !important;
  }
  .quotes-info-left p,
  .quotes-info-right p {
    font-size: 50px;
    letter-spacing: 0.5px;
  }
  .quotation-number {
    font-size: 14px !important;
  }
  .label-option {
    font-size: 12px !important;
  }
  .option {
    width: 100%;
    height: 195px !important;
  }
  .option p {
    font-size: 12px;
  }
  .img-icon-option-purple,
  .img-icon-option-yellow {
    width: 45px !important;
    height: 45px !important;
  }
  .second-layout {
    height: 450px;
  }
  .circle-stepper {
    width: 20px;
    height: 20px;
    background: var(--main-color);
    border-radius: 100px;
    border: 4px solid;
    position: relative;
    margin: 0px 100px 0 30px;
  }

  .vertical-line-stepper {
    width: 2px;
    height: 40px;
    background: #00000014;
    margin: 0px 100px 0 39px;
  }
  .vertical-line-stepper-hidden {
    width: 2px;
    height: 40px;
    margin: 0px 100px 0 39px;
    display: table-footer-group !important;
  }
  .information-quotation {
    position: relative;
    top: -64px;
    left: 90px;
    height: 0px;
  }
  .quotation-number {
    color: var(--secondary-color);
    font-size: 18px !important;
  }
  .p-date {
    font-size: 15px;
  }
}
@media only screen and (min-width: 677px) and (max-width: 769px) {
  .modal-calculadora-header h2 {
    font-size: 2vw;
  }
  .modal-calculadora-options {
    padding: 0px;
  }
  .quotes-info-left,
  .quotes-info-right {
    margin-top: 20px;
  }
  .quotes-info-left h2,
  .quotes-info-right h2 {
    font-size: 20px !important;
  }
  .quotes-info-left p,
  .quotes-info-right p {
    font-size: 50px;
    letter-spacing: 0.5px;
  }
  .quotation-number {
    font-size: 14px !important;
  }
  .label-option {
    font-size: 12px !important;
  }
  .option {
    width: 100%;
    height: 215px !important;
  }
  .option p {
    font-size: 12px;
  }
  .img-icon-option-purple,
  .img-icon-option-yellow {
    width: 45px !important;
    height: 45px !important;
  }
  .second-layout {
    height: 450px;
  }
  .circle-stepper {
    width: 20px;
    height: 20px;
    background: var(--main-color);
    border-radius: 100px;
    border: 4px solid;
    position: relative;
    margin: 0px 100px 0 30px;
  }

  .vertical-line-stepper {
    width: 2px;
    height: 40px;
    background: #00000014;
    margin: 0px 100px 0 39px;
  }
  .vertical-line-stepper-hidden {
    width: 2px;
    height: 40px;
    margin: 0px 100px 0 39px;
    display: table-footer-group !important;
  }
  .information-quotation {
    position: relative;
    top: -64px;
    left: 90px;
    height: 0px;
  }
  .quotation-number {
    color: var(--secondary-color);
    font-size: 18px !important;
  }
  .p-date {
    font-size: 15px;
  }
}
@media only screen and (min-width: 555px) and (max-width: 676px) {
  .option {
    width: 100%;
    height: 300px !important;
  }
  .quotes-info-left,
  .quotes-info-right {
    margin-top: 20px;
  }
  .quotes-info-left h2,
  .quotes-info-right h2 {
    font-size: 16px !important;
  }
  .option p {
    font-size: 12px !important;
    margin-top: 1px !important;
  }
  .option h2 {
    font-size: 13px !important;
  }
  .quotation-number {
    color: var(--secondary-color);
    font-size: 15px !important;
  }
  .p-date {
    font-size: 13px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 554px) {
  .option {
    width: 100%;
    height: 423px !important;
  }
  .quotes-info-left,
  .quotes-info-right {
    margin-top: 152px;
  }
  .quotes-info-left h2,
  .quotes-info-right h2 {
    font-size: 16px !important;
  }
  .option p {
    font-size: 12px !important;
    margin-top: 28px !important;
  }
  .option h2 {
    font-size: 13px !important;
  }
  .quotation-number {
    color: var(--secondary-color);
    font-size: 13px !important;
    max-width: 95% !important;
  }
  .p-date {
    font-size: 12px;
  }
  .information-quotation {
    position: relative;
    top: -100px;
    left: 67px;
    height: 0px;
  }
}
