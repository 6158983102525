.customer-credits-account-modal-wrapper {
  background: white;
  width: calc(100vw - 500px);
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & ::-webkit-scrollbar {
    display: block;
    height: 7px;
    background: #f2f2f2;
    border-radius: 10px;
  };

  & ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #e1e1e1;
  }
}

.new-table-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: calc(var(--padding-top-bar) + 8px);
  padding-left: calc(var(--padding-left-bar) + 30px);
  padding-right: 30px;
  height: 100%;
  background-color: var(--main-color);
  width: calc(100vw - 491px);
  overflow: hidden;
}

.new-table-container {
  margin-bottom: 20px;
  padding-bottom: 7px;
}

.new-table-container-table {
  width: 100%;
}

.new-table-container-table tbody {
  background-color: var(--main-color);
  border-bottom: 1px solid var(--border-light-color);
  max-height: calc(100vh - 300px);
  flex-direction: column;
  justify-content: flex-start;
}

.new-table-container-table th {
  font-size: 14px;
  font-weight: normal;
  padding: 12px 21px;
  border: 1px solid var(--border-light-color);
  background-color: var(--main-color-dark-color);
  color: var(--inactive-color-dark);
}

.new-table-content-row {
  background-color: var(--main-color);
}
.new-table-content-col {
  border: 1px solid var(--border-light-color);
  border-bottom: none;
  border-top: none;
  font-size: 14px;
  font-weight: normal;
  padding: 12px 21px;
  color: var(--inactive-color-dark);
}

.container-SD {
  display: flex;
  flex-direction: column;
  padding: calc(var(--padding-top-bar) + 8px) 12px 12px
    calc(var(--padding-left-bar) + 12px);
  height: 100%;
  background-color: var(--main-color);
  width: calc(100vw - 491px);
  overflow: auto;
  overflow-x: auto;
}

.table-sapce {
  width: 100%;
  padding: 0 20px;
}

.container-SD {
  display: flex;
  flex-direction: column;
  padding: calc(var(--padding-top-bar) + 8px) 12px 12px
    calc(var(--padding-left-bar) + 12px);
  height: 100%;
  background-color: var(--main-color);
  width: calc(100vw - 491px);
  overflow: auto;
  overflow-x: auto;
}

.table-sapce {
  width: 100%;
  padding: 0 20px;
}

.customer-credits-account-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 0;
}

.customer-credits-account-modal-title {
  font-size: 1.47vw;
  color: var(--secondary-color);
}

.customer-credits-account-modal-close {
  cursor: pointer;
}

.customer-credits-account-modal-search-wrapper {
  background-color: var(--main-color-dark-color);
  padding: 10px;
  width: 100%;
  border-top: 1px solid var(--border-light-color);
  border-left: 1px solid var(--border-light-color);
  border-right: 1px solid var(--border-light-color);
  display: flex;
  gap: 10px;
}

.customer-credits-account-modal-search {
  width: 600px;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0 4px;
  position: relative;
}

.customer-credits-account-modal-search > input {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  outline: none;
  border: 1px solid var(--border-light-color);
  padding: 0 16px;
  font-size: 16px;
}

.customer-credits-account-modal-search-icon,
.customer-credits-account-modal-search > img {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.customer-credits-account-modal-button {
  border: none;
  outline: none;
  height: 40px;
  padding: 0 16px;
  border-radius: 5px;
  background-color: var(--secondary-color);
  color: white;
  font-size: 16px;
}
.customer-credits-account-modal-button:hover {
  opacity: 0.9;
}

.single-product-cart-a-badge {
  padding: 4px 8px;
  width: fit-content;
  border-radius: 5px;
  color: black;
}

.single-product-cart-a-badge.no {
  background-color: var(--red-badge);
}

.single-product-cart-a-badge.yes {
  background-color: var(--green-badge);
}

.customer-credits-account-modal-empty {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--secondary-color);
  margin-top: 20px;
}
