.vertical-navbar {
  height: 100vh;
  z-index: 99999;
  background-color: var(--secondary-color);
  position: fixed;
  width: var(--padding-left-bar);
  transition: 80ms linear;
}
.vertical-navbar-submenu {
  height: 100vh;
  z-index: 99999;
  background-color: var(--main-color);
  position: fixed;
  transition: 80ms linear;
  box-shadow: 2px 0px 0px #00000029;
}

.nav-icons-container {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.v-nav-icon {
  margin-bottom: 10px;
  width: 100%;
  padding: 11px 25px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
.v-nav-icon-submenu {
  margin-bottom: 10px;
  width: 100%;
  padding: 40px 0px 10px 10px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}
.v-nav-icon-submenu-grandchildren {
  cursor: pointer;
  width: 100%;
  display: flex;
  text-decoration: none;
  align-items: center;
  margin-top: 5px;
  padding: 12px 0px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.v-nav-icon-submenu-grandchildren:hover {

  background-color: var(--secondary-color);
}

.v-nav-icon-submenu-grandchildren:hover .link-text-submenu-grandchildren {
  color: #fff !important; /* El color que deseas cuando haces hover */
}
.v-nav-icon-submenu-grandchildren:hover img {
  filter: brightness(0) invert(1) !important;
}

.img-invert {
  filter: brightness(0) invert(1) !important;
}

.v-nav-icon-submenu-children {
  width: 100%;
  padding: 10px 10px;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.link-text-submenu-children {
  font: normal normal 600 16px/20px Open Sans;
  color: var(--secondary-color);
  margin-bottom: 20px;
}
.v-nav-icon-content-open-submenu-grandchildren,
.v-nav-icon-content-open-submenu-grandchildren p {
 
  display: flex;
  align-items: center;
  color: var(--secondary-color);
  margin-left: 10px;
  font-weight: 400;
}

.v-nav-icon-content-open-submenu-grandchildren img {
  margin-left: 20px;
}


.v-nav-icon-content {
  display: flex;
  justify-content: center;
}

.v-nav-icon-content-open {
  display: flex;
  gap: 25px;
  justify-content: left;
  align-items: center
}

.unique {
  font-size: 1.7em;
  color: var(--main-color);
}

.link-text {
  font-size: 1.6em;
  display: none;
  color: var(--main-color);
}
.link-text-submenu {
  width: 90%;
  font-size: 1.6em;
  color: var(--secondary-color);
  font-weight: 600;
}

.line-separator {
  border: none;
  height: 1px;
  width: 90%;
  background-color: var(--secondary-color);
  opacity: 1;
}

.badge-color {
    background-color: #FF2A40;
    font-size: 10px;
    color: #FFFFFF;
    border-radius: 19px;
    padding: 0px 10px;
}

.v-nav-icon:hover {
  background-color: var(--secondary-color);
  opacity: 0.8;
}


@media only screen and (min-width: 500px) {
  .vertical-navbar {
    min-width: 70px;
    height: 100%;
    top: 0;
  }

  .v-nav-icon .sp.open {
    font-size: 1.7em;
    color: var(--main-color);
  }

  .v-nav-icon:nth-child(1) {
    margin: 10px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 350px) {
  /* .vertical-navbar{
        display: none;
    } */
  .bm-burger-button {
    display: flex;
  }
}

@media only screen and (min-width: 500px) {
  .vertical-navbar {
    display: flex;
  }
  .bm-burger-button {
    display: none;
  }
}

.v-nav-icon-children .v-nav-icon:hover {
  background: none;
  opacity: 1;
}

.v-nav-icon-children a.v-nav-icon:hover {
  background: none;
  opacity: 0.8;
}

.v-nav-icon-parent {
  flex-direction: column;
  gap: 26px;
  padding-left: 5rem;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-top: 10px;
  background-color: #022767;
}
.v-nav-icon-children-submenu {
  display: flex !important;
  flex-direction: column;
  padding: 0;
  margin-top: 10px;
}

.v-nav-icon-content-open-submenu, .v-nav-icon-content-open-submenu-grandchildren {
  list-style: none;
}
.v-nav-icon-children a.v-nav-icon {
  margin: 0;
  padding: 0;
}

.v-nav-icon-parent {
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.v-nav-icon-parent .v-nav-icon-content-open {
  padding-left: 25px;
}
.title-new {
  background-color: var(--third-color);
  border-radius: 13px;
  width: 31px;
  height: 9px;
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 8px;
  font-family: 'Open Sans', sans-serif;
  padding-left: 3px;
  padding-right: 2px;
  position: absolute;
  left: 151px;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .v-nav-icon:nth-child(1) {
    margin: 10px;
  }
  .v-nav-icon {
    padding: 11px 20px;
  }
}
@media only screen and (min-width: 1367px) and (max-width: 1920px) {
  .v-nav-icon:nth-child(1) {
    margin: 10px;
  }
  .v-nav-icon {
    padding: 20px 20px;
  }
}
@media only screen and (min-width: 1921px) and (max-width: 3500px) {
  .v-nav-icon:nth-child(1) {
    margin: 10px;
  }
  .v-nav-icon {
    padding: 30px 20px;
  }
}
