.action-table {
  width: 100%;
  border-bottom: 1px solid var(--border-light-color);
}

.action-table-topbar {
  background-color: var(--main-color-dark-color);
  border: 1px solid var(--border-light-color);
  padding: 25px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: none;
}

.warehouse-search {
position: relative;
}

.warehouse-search .warehouse-search-icon {
  position: absolute;
  top: 13px;
  right: 18px;
  cursor: pointer;
}

.warehouse-search input {
  width: 100%;
  height: 100%;
  outline: none;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  padding: 9px 18px;
  font-size: 16px;
}

.action-table th {
  font-size: 14px;
  padding: 12px 23px;
  border: 1px solid var(--border-light-color);
  background-color: var(--main-color-dark-color);
}

.action-table tr {
  font-size: 14px;
  background-color: var(--main-color);
}

.action-table td {
  padding: 21px 12px;
  border: 1px solid var(--border-light-color);
  border-bottom: none;
  border-top: none;
  gap: 10px;
}

.action-table tfoot {
  border: 1px solid var(--border-light-color);
}

.action-table-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action-table-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.action-table-buttons button {
  border-radius: 5px;
  font-size: 14px;
  border: none;
  padding: 11px 23px;
}

.button-disabled {
  border: none;
  cursor: none;
}

.btn-action-create-quote {
  font-size: 14px;
  border: none;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 31px;
  border-radius: 5px;
  color: var(--main-color);
  background: var(--secondary-color);
}

.icon-in-table {
  color: #003082;
  font-size: 2rem;
  cursor: pointer;
}

.action-table-cell {
  border-radius: 5px;
}

.action-table-cell {
  border-radius: 5px;
}

.action-table-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.action-table-icons .cotizacion-tooltip-buttons {
  display: flex;
}

.filter-popover {
  font-size: 1.4rem;
  background: white;
  padding: 16px;
  border-radius: 6px;
}
.filter-popover-warehouses {
  font-size: 1.4rem;
  background: white;
  padding: 25px;
  border-radius: 6px;
}

.container-warehouses {
  overflow-y: scroll;
  max-height: 300px;
}

.btn-apply-filter {
  border: none;
  background-color: var(--secondary-color);
  color: var(--main-color);
  padding: 13px 31px;
  border-radius: 5px;
  max-height: 44px;
  letter-spacing: 0.34px;
  max-lines: 1;
  width: 100%;
  margin: 2px;
  font-size: 1.4rem;
}
.container-buttons-clients-is-quote {
  text-decoration: none;
  display: flex;
  gap: 10px;
}
.container-buttons-clients {
  display: block;
  gap: 0;
}

.btn-clear-filter {
  border: 1px solid var(--secondary-color);
  background-color: var(--main-color);
  color: var(--secondary-color);
  padding: 13px 26px;
  border-radius: 5px;
  max-height: 44px;
  letter-spacing: 0.34px;
  max-lines: 1;
  width: 100%;
  margin: 2px;
  font-size: 1.4rem;
}

.status-filter-checkbox {
  display: flex;
  gap: 14px;
}

.sd-list-container {
  width: 19rem;
  padding-left: 1rem;
  padding: 16px;
  background: white;
}

.sd-list-item {
  list-style: none;
  font-size: 11px;
  margin: 7px;
}

.sd-number-container {
  display: flex;
  justify-content: space-between;
}

.text-filter-popover-title {
  color: var(--secondary-color);
}
.MuiPickersPopper-root .MuiPickersDay-root {
  font-size: 16px;
}
.MuiPickersPopper-root .MuiPickersCalendarHeader-label {
  font-size: 16px;
}
.MuiPickersPopper-root .MuiPickersCalendarHeader-switchViewIcon {
  font-size: 16px;
}
.MuiPickersPopper-root .MuiPickersArrowSwitcher-root {
  font-size: 16px;
}
.MuiPickersPopper-root .MuiDayPicker-header {
  font-size: 16px;
}
.MuiPickersPopper-root .MuiPickersCalendarHeader-root {
  font-size: 16px;
}
.MuiPickersPopper-root .MuiYearPicker-root {
  font-size: 16px;
}
.MuiPickersPopper-root .MuiDayCalendar-header .MuiTypography-root {
  font-size: 16px;
}
.MuiPickersPopper-root .MuiPickersYear-yearButton {
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  /* .btn-action-create-quote {
        padding: 12px 15px;
    }
    .action-table-buttons button{
        padding: 11px 20px;
    } */
  .action-table-cell {
    font-size: 12px;
  }
  .action-table th {
    font-size: 12px;
  }
  .action-table tr {
    font-size: 12px;
  }
  .popover-warehouses {
    left: 80px !important;
  }
}
