.quote-activity-status {
  background-color: var(--open-quotes);
  border-radius: 5px;
}

.quote-activity-tooltips {
  display: flex;
  justify-content: space-around;
}

.status-icon {
  position: absolute;
  top: 4px;
  left: 2px;
  width: 10px;
  height: 35px;
  border-radius: 5px;
  background-color: var(--open-quotes);
}

.strong-text {
  font-weight: 600;
}

.fa-square-pen {
  font-size: 2em;
  color: #21c5df;
}

.fa-trash-can {
  font-size: 2em;
  color: #f64e60;
}
