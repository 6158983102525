.userNotfound {
  color: red;
  font-size: 14;
  margin-top: 4;
}

.editClienteButton {
  display: flex;
  gap: 8px;
}

.editIcon {
  cursor: pointer;
}

.editButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.editButtonsWrapper > button {
  border: none;
  outline: none;
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.cancelButton {
  background-color: white;
  color: var(--secondary-color);
}

.saveButton {
  background-color: var(--secondary-color);
  color: white;
}
.disabled {
  background-color: var(--disabled);
  cursor: not-allowed;
}

.errorInput {
  border: 1px solid red !important;
}
.error {
  color: var(--error-color);
  font-size: 12px;
  margin-top: -8px;
  line-height: normal;
  margin-bottom: 8px;
}

.loadingButton {
  display: grid;
  place-content: center;
  min-width: 102px;
}

.loadingButton span {
  color: white;
}

.top_margin{
  margin-top: 2rem;
}

.clientId-disable{
  color: gray;
  margin-left: '1rem';
}
