.calculator-summary-main {
    padding-top: var(--padding-top-bar);
    padding-left: var(--padding-left-bar);
    display: auto;
  }

.container-calculator-summary {
    height: 100%;
    background-color: var(--main-color);
    padding: 10px 10px 10px 20px;

}

.calculator-summary-table {
    background-color: var(--main-color);
}

.h2-tittle{
    margin-left: 1rem;
}

.p-description {
    margin-bottom: 1rem;
    margin-left: 1rem;
    font-size: small;
    color: red;
}

.calculator-summary-tabletable {
    border-collapse: collapse;
}

.calculator-summary-table tbody tr {
    border-bottom: none;
}

.calculator-summary-table tbody th,
.calculator-summary-table tbody td {
    border-bottom: none;
    padding: 20px 5px !important;
    text-align: center;
}

.cantidad {
    border: 1px solid #DBDBDB !important;
}

.product-cart-totals {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 0.83vw;
    padding: 0 !important;
    row-gap: 2rem;
    margin-right: 10px;
    margin-left: 10px;
  }

.products-cart {
    box-shadow: none !important;
}