.table-header.columna-6 {
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 100px;
}

.table-sell-order.columna-6 {
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 100px;
}

.money-data.columna-6-special {
  grid-template-columns: 1fr 3fr 1fr 1fr 1fr 100px;
}

.sell-order-generada-container {
  display: flex;
  flex-direction: column;
  padding: calc(var(--padding-top-bar) + 8px) 12px 12px
    calc(var(--padding-left-bar) + 12px);
}

.sell-order-generada {
  background-color: var(--main-color);
  border-radius: 5px;
  padding: 49px 53px;
  box-shadow: 0px 1px 4px #15223214;
  overflow: hidden;
}

.sell-order-advice {
  background-color: #d6ecfa;
  border-radius: 5px;
  margin-bottom: 19px;
  padding: 13px 26px;
  box-shadow: 0px 1px 4px #15223214;
  overflow: hidden;
}

.info-advice-text {
  padding-top: 9px;
}

.info-advice-text-title {
  font-weight: var(--semibold);
}

.sell-order-generada .titulo-sell-order {
  font-size: 21px;
  letter-spacing: 0.42px;
}

.sell-order-editar-header {
  display: flex;
  justify-content: space-between;
}

.sell-order-share-buttons {
  display: flex;
  gap: 6px;
  font-size: 0.72vw;
}

.sell-order-share-buttons button {
  border: none;
  padding: 14px 77px;
  border-radius: 5px;
  color: var(--main-color);
  display: flex;
  align-items: center;
  gap: 8px;
}

.sell-order-share-buttons button:nth-child(1) {
  background-color: #11d29e;
  padding: 14px 77px;
}

.sell-order-share-buttons button:nth-child(2) {
  background-color: #12c1f8;
  padding: 13px 52px;
}

.sell-order-share-buttons button svg {
  font-size: 2.3rem;
}

.sell-order-info-gen-cliente,
.sell-order-info-gen-retiro,
.sell-order-info-gen-vendedor {
  padding: 21px 39px;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 5px;
  font-size: 1.4rem;
  position: relative;
}

.sell-order-info-gen-order .fa-square-pen,
.sell-order-info-gen-retiro .fa-square-pen,
.sell-order-info-gen-vendedor .fa-square-pen {
  position: absolute;
  font-size: 1.8rem;
  top: 21px;
  right: 27px;
}

.sell-order-info-gen-vendedor .status {
  display: inline-block;
  background-color: #11d29e;
  padding: 4px 8px;
  margin-top: 9px;
  border-radius: 5px;
}

.sell-order-info-gen-vendedor .status-pending {
  display: inline-block;
  background-color: #11d29e;
  padding: 4px 8px;
  margin-top: 9px;
  border-radius: 5px;
}

.order-sell-order-info-gen-codigo-qr {
  display: flex;
  justify-content: center;
  align-self: center;
}

.qr-data-style {
  width: 75%;
}

.product-action-buttons .fa-square-pen {
  margin: 0;
}

.sell-order-share-buttons a {
  text-decoration: none;
}

.delivery-info-container {
  margin-top: 45px;
}

.delivery-info-container .metodo-entrega-left {
  height: calc(100vh - 65px);
}

.delivery-info-container .metodo-entrega-main {
  padding-left: 50px;
}

#price_change_auth {
  color: var(--main-color);
  background-color: #ff2a40;
}

.top-action-table-buttons {
  display: flex;
  gap: 13px;
}

.top-action-table-buttons button {
  border: none;
  font-size: 18px;
  padding: 6px 12px;
  border-radius: 5px;
}

.sell-order-labels {
  display: flex;
  gap: 10px;
}

.footer-container {
  background-color: var(--main-color);
  width: 100%;
  margin-top: 15px;
  box-shadow: 0px 1px 4px #15223214;
  border-radius: 5px;
  font-size: 1.4rem;
  max-width: initial;
}

.footer-info {
  padding-top: 33px;
  padding-left: 53px;
  padding-bottom: 56px;

  font-size: 1.4rem;
}

.footer-left-text-title {
  font-weight: var(--semibold);
}

.footer-right-text-title {
  font-weight: var(--semibold);
}

.footer-left-text {
  margin-top: 17px;
}

.footer-right-row-container {
  padding-right: 133px;
  margin-top: 19px;
}

.footer-textarea-style {
  padding-right: 133px;
  height: 86px;
}

.sell-order-info-gen-text {
  padding-top: 9px;
  width: fit-content;
}

.save-button-footer-container {
  flex: auto;
  text-align: end;
  margin-right: 76px;
  margin-top: 18px;
}

.sell-order-save-button-footer {
  border: none;
  min-width: 245px;
  background-color: var(--secondary-color);
  color: var(--main-color);
  padding: 13px 31px;
  border-radius: 5px;
  max-height: 44px;
  letter-spacing: 0.34px;
  max-lines: 1;
  margin: 2px;
  font-size: 1.4rem;
}

.sell-order-save-button-footer:disabled {
  border: none;
  min-width: 245px;
  background-color: var(--inactive-color);
  color: var(--main-color);
  padding: 13px 31px;
  border-radius: 5px;
  max-height: 44px;
  letter-spacing: 0.34px;
  max-lines: 1;
  margin: 2px;
  font-size: 1.4rem;
}

@media (min-width: 0px) {
  .sell-order-info-gen {
    margin-top: 17px;
    display: grid;
    gap: 1%;
    grid-template-columns: 100%;

    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .sell-order-info-gen {
    margin-top: 17px;
    display: grid;
    gap: 2%;
    grid-template-columns: 50% 50%;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .sell-order-info-gen {
    margin-top: 17px;
    display: grid;
    gap: 2%;
    grid-template-columns: 32% 32% 32%;
    margin-bottom: 20px;
  }
}

.sell-order-toast-container {
  z-index: 99999999;
  margin-top: 30px;
}

.sell-order-toast-body {
  background-color: var(--error-color-opacity);
  border-radius: 0px 0px 5px 5px;
  font-size: 13px;
}

.sell-order-toast-header {
  font-size: 13px;
}

.moda-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
