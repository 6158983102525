.crear-cotizacion-main {
  padding: calc(var(--padding-top-bar) + 7px) 10px 0px
    calc(var(--padding-left-bar) + 12px);
  /* max-height: 100vh;
    overflow: hidden; */
}

.crear-cotizacion-main .row {
  --bs-gutter-x: 10px;
}

/* CHECK HERE */
.productos-cotizacion {
  /* position: sticky;
    top: 70px; */
  min-height: calc(100vh - 100px);
  background-color: var(--main-color);
  border-radius: 5px;
  box-shadow: 0px 1px 4px #15223214;
  min-width: 80%;
}

.productos-cotizacion-search {
  width: 100%;
  padding: 15px 19px 10px 19px;
  display: flex;
  position: relative;
}

.productos-cotizacion-search .search-product, .insert-customer-counted{
    width: 100%;
    padding: 12px 18px;
    border: 0.5px solid var(--inactive-color);
    border-radius: 5px;
    font-size: 1.041vw;
    color: #7E7E7E;;
    outline: none;
    height: 45px;
}

.productos-cotizacion-search button {
  margin-left: 10px;
  padding: 12px 21px;
  border: none;
  border: 0.5px solid var(--inactive-color);
  border-radius: 5px;
  height: 45px;
}

.cotizacion-content {
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cotizacion-content h2 {
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 0.4px;
}

.active-slider {
  background-color: var(--main-color);
}

.blue-svg {
  filter: invert(8%) sepia(97%) saturate(4496%) hue-rotate(215deg)
    brightness(94%) contrast(101%);
}

.filtering-options {
  position: absolute;
  right: 0;
  top: 69px;
  min-width: 411px;
  background-color: var(--main-color);
  z-index: 9999;
  padding: 0 25px;
}

.filtering-options::-webkit-scrollbar {
  display: none;
}

.min-max {
  display: inline-block;
  width: 90px;
  font-size: 1.5rem;
  padding: 6px;
}

.min-max::placeholder {
  color: #7e7e7e;
  font-size: 1.5rem;
  padding: 6px;
}

.productos-cotizacion-departments {
  overflow: auto !important;
  height: 100% !important;
  background-color: white;
  padding: 10px;
}

.productos-cotizacion-departments .container {
  max-height: calc(100vh - 127px);
}

/* CHECK HERE */
.products-cotizacion-content-container {
    /* QUITARLO CUANDO SE ABRE UN MODAL */
    height: calc(100vh - var(--padding-top-bar));
    overflow: auto;
    padding-bottom: 30px;
}

.cotizacion-content-message {
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cotizacion-content-message h2 {
  font-size: 2rem;
  letter-spacing: 0.4px;
  font-weight: 600;
}

.modal-box-client {
  width: 100%;
  padding-left: 70px;
  padding-top: 45px;
  height: 100%;
  background-color: white;
  outline: none;
}

.blur{
  z-index: 0 !important;
}
.restore-container-cliente {
  padding: 50px 20px 50px 0;
}

.restore-container-cliente .modal-header-restore {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.restore-container-cliente .modal-header-restore h2 {
  font-size: 2.1em;
  line-height: 28px;
  letter-spacing: 0.21px;
  color: var(--secondary-color);
  padding-left: 5%;
}

.modal-box-client .modal-header-restore h3 {
  font-size: 2.1em;
  line-height: 28px;
  letter-spacing: 0.21px;
  color: var(--secondary-color);
  font-weight: 400;
  padding-left: 5%;
}

.buscar-cliente-header {
  padding: 15px 44px;
  background-color: var(--main-color);
}

.filtering-options h3 {
  font-size: 1.04vw;
  margin-bottom: 9px;
  font-weight: var(--bold);
}

.category-label {
  font-size: 1.04vw;
}

.product-category {
  margin-bottom: 13px;
}

.product-category input {
  margin-right: 11px;
}

.categories-ver-todas {
  font-size: 1.04vw;
}

.current-filtering-options {
  padding: 0 19px;
  display: flex;
  width: 100%;
}

::-webkit-scrollbar {
  height: 8px;
  overflow: visible;
  width: 8px;
  margin-top: 20px;
}

.current-filtering-option {
  font-size: 1vw;
  font-weight: var(--semibold);
  display: flex;
  flex: 0 0 auto;
  gap: 21px;
  margin-right: 13px;
  background-color: #f1f1f1;
  padding: 5px 15px;
  align-items: center;
  border-radius: 25px;
}

.current-filtering-option img {
  background-color: #000000;
  border-radius: 50%;
  padding: 3px 3px;
}

.load-more-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.load-more-button button {
  font-size: 1.5rem;
  border-radius: 5px;
  color: var(--main-color);
  background-color: var(--secondary-color);
  border: none;
  padding: 5px 20px;
}

.productos-cotizacion-header {
  background-color: white;
}

.search-bar-input-crear-cot {
  position: relative;
  display: flex;
  width: 100%;
}
