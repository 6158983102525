@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

:root {
  --main-color: #ffffff; /* Commonly used white color */
  --main-color-dark-color: #f8f9fa;
  --secondary-color: #003082; /* Commonly used blue color */
  --secondary-color-light: #0055b8;
  --button-color: #3699ff; /* Buttons Color (Blue) */
  --third-color: #ffde20; /* Buttons Color (Yellow) */
  --inactive-color: #c8c8c8;
  --inactive-color-light: #f3f3f3;
  --inactive-color-dark: #5b5b5b;
  --placeholder-color: #c1c1c1;
  --placeholder-color-dark: #7e7e7e;
  --location-color-red: #ed1c24;
  --shadow-color: #15223214;
  --error-color: #ff2a40;
  --error-color-opacity: rgba(255, 42, 63, 0.512);
  --border-light-color: #e1e1e1;
  --dark: #000000;
  --input-background-color: #afafaf;
  --disabled: #afafaf;
  --red-badge: rgb(255, 42, 64, 0.5);
  --green-badge: rgb(17, 210, 158, 0.5);
  --dark-color-1: #010101;
  --padding-top-bar: 65px; /* Horizontal nav size */
  --padding-left-bar: 70px; /* Vertical nav size */
  --semibold: 600;
  --bold: 700;
  --light: 100;
  --regular: 400;
  background-color: #f8f9fd;
  font-family: 'Open Sans';
  font-size: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p {
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: normal;
}

button:disabled {
  cursor: not-allowed;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c1c1c1;
}

.MuiPopover-paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: none !important;
  background: transparent !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  p {
    font-size: 13px;
  }
}

/* size: 27.94cm 21.59cm; */
@media print {
  @page {
    /* size: 650px 503.2px; */
    /* size: 450px 584.3px; */
    /* size: 27.9cm 21.6cm; */
  }
}

/* @media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
} */
